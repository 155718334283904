import React from "react";
import { styled } from "@mui/material/styles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Link } from "@mui/material";
import { indigo } from "@mui/material/colors";

const StyledTimelineItem = styled(TimelineItem)({
  //   width: "50px",
  "&::before": {
    padding: 0,
    content: "none",
  },
});

const style = {
  textDecoration: "none",
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "14px",
  letterSpacing: 0,
  fontWeight: 600,
  // display: "block",
};

export default function RightAlignedTimeline({ application }) {
  //   console.log("customers", customers);
  return (
    <Timeline
      align="right"
      style={{ margin: 0, padding: "0 0 0 0", position: "fixed" }}
    >
      <StyledTimelineItem style={{ "::before": { padding: 0 } }}>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Link style={style} href="#assetDetails">
            Asset
          </Link>
        </TimelineContent>
      </StyledTimelineItem>
      <StyledTimelineItem style={{ content: { "&::before": "none" } }}>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Link style={style} href="#loanDetails">
            Loan details
          </Link>
        </TimelineContent>
      </StyledTimelineItem>
      <StyledTimelineItem style={{ content: { "&::before": "none" } }}>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Link style={style} href="#entityDetails">
            {application?.entity?.entityName || "Entity"}
          </Link>
        </TimelineContent>
      </StyledTimelineItem>
      {application?.customers?.map((customer) => (
        <StyledTimelineItem style={{ content: { "&::before": "none" } }}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Link
              style={style}
              href={`#applicant-${customer.firstName}-${customer.lastName}`}
            >
              {`${customer.firstName || "New"} ${
                customer.lastName || "customer"
              }`}
            </Link>
          </TimelineContent>
        </StyledTimelineItem>
      ))}
    </Timeline>
  );
}
