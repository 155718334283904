import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  uploadDocument,
  saveValue,
  userDetailsSelector,
  startVerification,
  getIdentityVerification,
} from "../store/slices/applicationFormSlice";
import Document from "./Document";
import { Divider, Skeleton, Stack } from "@mui/material";
import usePoll from "src/utils/usePoll";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/styles";

const Input = styled("input")({
  display: "none",
});

export default function Identity({ customer, applicationId }) {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { uploadIDLoading = "", sendingIDLink } = useSelector(userDetailsSelector);

  const [startedCount, setStartedCount] = useState(0);
  const [finishedCount, setFinishedCount] = useState(0);

  const [delay, setDelay] = useState(null);

  const onStart = () => {
    setDelay(30000);
  };

  const onStop = () => {
    setDelay(null);
  };

  function handleStartIdentityVerification(customerId, mobile) {
    dispatch(startVerification({ customerId, applicationId, mobile }));
    handleGetIdentityVerification(customer?._id);
  }

  const [IDUploadButtonBackground, setIDUploadButtonBackground] =
    useState("secondary");
  const [IDUploadButtonText, setIDUploadButtonText] = useState("Upload ID");

  const [sendIDLinkBackground, setSendIDLinkBackground] = useState("secondary");
  const [sendIDLinkText, setSendIDLinkText] = useState("Send ID Capture Link");

  function handleUploadID(event) {
    dispatch(saveValue({ uploadIDLoading: true }));
    const formData = new FormData();
    console.log(event.target.files[0]);
    // Update the formData object
    formData.append("file", event.target.files[0]);
    formData.append("applicationId", applicationId);
    formData.append("customerId", customer._id);
    formData.append("name", "ID document");

    // Details of the uploaded file
    console.log(customer._id);
    console.log(customer.firstName);

    dispatch(uploadDocument(formData));
    event.target.input = null;
    // Request made to the backend api
    // Send formData object
  }

  function handleIDButtonSuccessStyle() {
    setIDUploadButtonBackground("success");
    setIDUploadButtonText("Upload ID Successful");

    setTimeout(() => {
      setIDUploadButtonBackground("secondary");
      setIDUploadButtonText("UPLOAD ID");
    }, 6000);
  }

  function handleSendIDLinkSuccessStyle() {
    setSendIDLinkBackground("success");
    setSendIDLinkText("Link sent");

    setTimeout(() => {
      setSendIDLinkBackground("secondary");
      setSendIDLinkText("Send ID capture link");
    }, 6000);
  }

  usePoll(async () => {
    setStartedCount((count) => count + 1);
    dispatch(
      getIdentityVerification({
        customer: customer._id,
        // application: applicationId,
      })
    );
    setFinishedCount((count) => count + 1);
  }, delay);

  const handleGetIdentityVerification = (customerId) => {
    onStart();
  };

  useEffect(() => {
    if (customer?.documents?.length > 0) setDelay(null);
    // handleGetIdentityVerification(customer?._id);
  }, [customer]);

  const [imageWidth, setImageWidth] = useState();

  useEffect(() => {
    console.log("width", ref.current ? ref.current.offsetWidth : 0);
    setImageWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  useEffect(() => {
    if (uploadIDLoading === false) {
      handleIDButtonSuccessStyle()
      dispatch(saveValue({ uploadIDLoading: null }))
    }
  }, [uploadIDLoading]);

  useEffect(() => {
    if (sendingIDLink === false) {
      handleSendIDLinkSuccessStyle()
      dispatch(saveValue({ sendingIDLink: null }))
    }
  }, [sendingIDLink]);

  return (
    <>
      <Grid container item xs={12} spacing={0} style={{ marginBottom: "30px" }}>
        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
            Identity
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Stack
              spacing={1}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <LoadingButton
                loading={sendingIDLink}
                color={sendIDLinkBackground}
                variant="outlined"
                // style={{ marginBottom: "5px" }}
                disabled={
                  !customer?.phoneNumbers?.filter(
                    (a) => a.status === "current"
                  )[0]?.number
                }
                onClick={() => {
                  handleStartIdentityVerification(
                    customer._id,
                    customer?.phoneNumbers.filter(
                      (a) => a.status === "current"
                    )[0].number
                  );
                  dispatch(saveValue({ linkSent: true }));
                  dispatch(saveValue({ sendingIDLink: true }));
                }}
              >
                {sendIDLinkText}
              </LoadingButton>
              <Stack direction="row" alignItems="center" spacing={2}>
                <label htmlFor={`upload-id-${customer._id}`}>
                  <Input
                    accept="*"
                    id={`upload-id-${customer._id}`}
                    multiple
                    type="file"
                    value={null}
                    onChange={(event) => handleUploadID(event)}
                  />
                  <LoadingButton
                    loading={uploadIDLoading}
                    variant="contained"
                    component="span"
                    color={IDUploadButtonBackground}
                  >
                    {IDUploadButtonText}
                  </LoadingButton>
                </label>
              </Stack>
            </Stack>
            {!customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
              ?.number && (
                <Typography variant="caption">
                  Requires customer mobile phone number
                </Typography>
              )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
