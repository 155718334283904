import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  userSelector,
  validateEmail,
  updateUser,
  updatePhoneNumber,
  changePassword,
  getUser,
} from "../store/slices/userSlice";

import regex from "../utils/regex";
import { current } from "@reduxjs/toolkit";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PaymentButton() {
  const stripe = useStripe();
  const elements = useElements();

  // console.log("user", user);

  async function handlePayment() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://example.com/account/payments/setup-complete",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <>
      <LoadingButton
        // fullWidth
        // endIcon={<SendOutlinedIcon />}
        // loading={isChangingPassword}
        // // loadingIndicator="Generating..."
        // loadingPosition="end"
        variant="contained"
        // disabled={!canSubmit && !changePasswordSuccess}
        color="secondary"
        // style={{ margin: "20px 0 40px", justifySelf: "start" }}
        onClick={handlePayment}
      >
        Save card details
      </LoadingButton>
    </>
  );
}
