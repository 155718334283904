import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
  saveValue,
  startApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
} from "../store/slices/applicationFormSlice";
// import _ from "lodash";

export default function StartApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isNewApplication } = useSelector(userDetailsSelector);
  const { _id } = useSelector(applicationSelector);

  useEffect(() => {
    console.log("isNewApplication", isNewApplication);
    console.log("_id", _id);
    if (isNewApplication && _id) {
      dispatch(saveValue({ isNewApplication: false }));
      dispatch(clearState());
      navigate(`/dashboard/application/${_id}`);
      // window.location.reload()
    }
  }, [isNewApplication]);

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={async () => {
          console.log("start application");
          dispatch(startApplication());
        }}
        style={{ marginRight: "30px" }}
      >
        New application
      </Button>
    </>
  );
}
