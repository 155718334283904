import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

console.log(process.env.REACT_APP_API_URL);

function headers(token) {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export const getAllCustomers = createAsyncThunk(
  "customers/get",
  async (userId, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/customer/all`,
        method: "POST",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("response", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: {},
  reducers: {
    saveValue: (state, action) => {
      return _.merge(state, action.payload);
    },
  },
  extraReducers: {
    [getAllCustomers.fulfilled]: (state, { payload }) => {
      console.log("payload", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state = _.merge(state, payload.data);
      // console.log(JSON.stringify(state, null, 4));
    },
    [getAllCustomers.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllCustomers.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },
  },
});

export const { saveValue } = customerSlice.actions;

// export const store = configureStore({
//   reducer: {
//     applications: applicationsSlice.reducer,
//   },
// });

export const customerSelector = (state) => state.customer;
