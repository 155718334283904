import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";
import {
  userSelector,
  getAllTransactions,
  getCustomerBilling,
} from "../store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    // editable: true,
  },
  {
    field: "fee",
    headerName: "Fee",
    type: "string",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    // editable: true,
  },
  { field: "id", headerName: "ID", width: 90 },
  // {
  //   field: "applicant",
  //   headerName: "Applicant",
  //   width: 250,
  //   // editable: true,
  // },

  // {
  //   field: "fee",
  //   headerName: "Fee",
  //   width: 100,
  //   // editable: true,
  // },
  // {
  //   field: "applicationId",
  //   headerName: "Application ID",
  //   width: 150,
  //   // editable: true,
  // },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Billing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const transactions = useSelector(userSelector);

  const [rows, setRows] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleGetAllTransactions() {
    dispatch(getAllTransactions());
  }

  function handleGetCustomer() {
    console.log("handle");
    dispatch(getCustomerBilling());
  }

  useEffect(() => {
    dispatch(getCustomerBilling());
  }, []);

  useEffect(() => {
    console.log(user?.billing);
  }, [user]);

  useEffect(() => {
    // console.log(transactions);
    setRows(
      user?.billing?.invoices?.data?.map((item) => {
        // const newDoc = _.merge({}, doc);

        const date = new Date(item.createdAt);
        console.log(date);
        return {
          name: item.description,
          id: item.id,
          fee: `$${(item.unit_amount / 100).toFixed(2)}`,
          status: item.status,
          // date: item.status,
        };
      })
    );
    console.log("rows", rows);
  }, [user.billing]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      style={{ marginTop: "64px", padding: "20px" }}
    >
      <Grid item>
        <Typography
          variant="h1"
          style={{ fontSize: "32px", fontWeight: 800, letterSpacing: "-1.5px" }}
        >
          Billing
        </Typography>
      </Grid>
      <Grid item style={{ height: "66vh" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Transactions" {...a11yProps(1)} />
              {/* <Tab label="Billing histroy" {...a11yProps(2)} /> */}
              {/* <Tab label="Payment methods" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography variant="h4">Your plan</Typography>
            <Typography>
              You're on the {user?.billing?.metadata?.name} plan.
            </Typography>
            <Typography>Your balance is ${user?.billing?.balance}.</Typography>
          </TabPanel>
          <TabPanel value={value} index={1} style={{ height: "66vh" }}>
            <DataGrid
              onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;
              }}
              // onRowClick={(event) => {
              //   console.log(event);
              //   navigate(`/dashboard/customer/${event.id}`);
              // }}
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              // checkboxSelection
              disableSelectionOnClick
              style={{ background: "#ffffff", height: "66vh" }}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={2}></TabPanel> */}
          {/* <TabPanel value={value} index={2}></TabPanel> */}
        </Box>
      </Grid>
    </Grid>
  );
}
