import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import { getFileUrl } from "../store/slices/applicationFormSlice";
import { Link } from "@mui/material";
import { Paper } from "@mui/material";

export default function Document({ document }) {
  const dispatch = useDispatch();

  const documentUrl = useSelector(
    (state) => state?.userDetails.document[document._id]
  );

  const handleGetFileUrl = (document) => {
    dispatch(
      getFileUrl(
        document
        // application: applicationId,
      )
    );
    // console.log("identityVerification", identityVerification);
  };

  useEffect(() => {
    // if (document) {
    handleGetFileUrl(document);
    // console.log(documentUrl);
    // }
  }, [document]);

  function ImageComponent() {
    if (document.ext === ".webm") {
      return <video
        src={documentUrl}
        style={{
          height: "120px",
          width: "90px",
        }}></video>;
    }

    if (document.ext === ".pdf") {
      return (
        <Paper
          // elevation={0}
          style={{
            border: "1px solid",
            borderColor: "rgba(0,0,0,0.1)",
            width: "90px",
            height: "120px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "5px",
          }}
        >
          <PictureAsPdfTwoToneIcon />
        </Paper>
      );
    }
    return <Paper
      style={{
        height: "120px",
        width: "90px",
        background: `url(${documentUrl})`,
        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
      }}
    ></Paper>;
  }

  return (
    <>
      <Link href={documentUrl} size="small" color="primary">
        <Card
          elevation={0}
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ImageComponent />

          <Typography variant="subtitle2">{document?.name}</Typography>
        </Card>
      </Link>
    </>
  );
}
