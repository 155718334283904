import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
  saveValue,
  userSelector,
  validateEmail,
} from "../../store/slices/userSlice";

import regex from "../../utils/regex";

import ggfLogo from "../../ggf.gif";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Application() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState(" ");
  const [lastNameErrortext, setLastNameErrorText] = useState(" ");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(" ");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [hasOrganisation, setHasOrganisation] = useState(null);

  const { emailInUse, isCreatingAccount, createAccountSuccess } =
    useSelector(userSelector);

  async function handleRegistration() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      registerUser({
        firstName,
        lastName,
        email,
        password,
        organisation: hasOrganisation,
      })
    ).unwrap();
  }

  function handleFirstName(event) {
    setFirstName(event.target.value);

    if (regex.name.test(event.target.value)) {
      setFirstNameError(false);

      return setFirstNameErrorText(" ");
    }

    setFirstNameError(true);
    setFirstNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handleLastName(event) {
    setLastName(event.target.value);

    if (regex.name.test(event.target.value)) {
      setLastNameError(false);

      return setLastNameErrorText(" ");
    }

    setLastNameError(true);
    setLastNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handleEmail(event) {
    setEmail(event.target.value.trim());

    if (regex.email.test(event.target.value.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));
      return setEmailErrorText(" ");
    }

    setEmailError(true);
    setEmailErrorText("Invalid email");
  }

  async function handleCheckEmail() {
    await dispatch(validateEmail(email)).unwrap();
  }

  function handlePassword(event) {
    setPassword(event.target.value);

    console.log(event.target.value, regex.password.test(event.target.value));
    regex.password.test(event.target.value)
      ? setPasswordError(false)
      : setPasswordError(true);
  }

  useEffect(() => {
    if (emailInUse) {
      setEmailError(true);
      return setEmailErrorText("Email address already in use.");
    }

    setEmailError(false);
    return setEmailErrorText(" ");
  }, [emailInUse]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      !firstNameError &&
      !lastNameError &&
      !emailError &&
      !passwordError
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [
    firstName,
    lastName,
    email,
    password,
    firstNameError,
    lastNameError,
    emailError,
    passwordError,
  ]);

  useEffect(() => {
    console.log({ createAccountSuccess });
    if (hasOrganisation) return navigate("/dashboard/applications");
    if (createAccountSuccess) navigate("/setup/organisation");
  }, [createAccountSuccess]);

  useEffect(() => {
    console.log("query.get(oid)", query.get("oid"));
    if (query.get("oid")) setHasOrganisation(query.get("oid"));
  });

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={6}
          xl={5}
          height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
          textAlign="center"
          style={{ padding: "0 100px" }}
        >
          <img
            style={{
              height: "140px",
              imageRendering: "-webkit-optimize-contrast",
            }}
            src={ggfLogo}
          />
          {/* <Typography
            variant="h4"
            fontWeight="800"
            style={{ letterSpacing: "-1.8px" }}
          >
            Financeable
          </Typography> */}
          {/* <br />
          <br /> */}
          {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
          <br />
          {/* <br /> */}
          <Typography variant="h5" fontWeight="300">
            Sign up to the broker portal
          </Typography>
          <br />
          <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="firstName"
            label="First name"
            autoComplete="first-name"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={firstName}
            inputProps={{ maxLength: 24 }}
            onChange={handleFirstName}
            margin="dense"
            error={firstNameError}
            helperText={firstNameErrorText}
            // onBlur={() => {}}
          />

          <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="lastName"
            label="Last name"
            autoComplete="last-name"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={lastName}
            inputProps={{ maxLength: 24 }}
            onChange={handleLastName}
            margin="dense"
            helperText={lastNameErrortext}
            error={lastNameError}
            // onBlur={() => {}}
          />

          <TextField
            fullWidth
            // id="outlined-basic"
            type="email"
            name="email"
            label="Email"
            autoComplete="email"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={email}
            onChange={handleEmail}
            margin="dense"
            helperText={emailErrorText}
            error={emailError}
            onBlur={handleCheckEmail}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            fullWidth
            // id="outlined-basic"
            type="password"
            name="password"
            label="Password"
            autoComplete="password"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={password}
            onChange={handlePassword}
            error={passwordError}
            margin="dense"
            helperText="Must conatain an uppercase letter, a number, a special character (!@$%&*) and be 8 or more characters in length."
            inputProps={{ maxLength: 32 }}
            // onBlur={() => {}}
          />

          <LoadingButton
            fullWidth
            // endIcon={<SendOutlinedIcon />}
            loading={isCreatingAccount}
            // loadingIndicator="Generating..."
            loadingPosition="end"
            variant="contained"
            disabled={!canSubmit}
            style={{ margin: "20px 0 40px" }}
            onClick={async () => await handleRegistration()}
          >
            Create my account
          </LoadingButton>
          <Typography>
            Already have an account? <Link to="/login">Login</Link>
          </Typography>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          height="100vh"
          justifyContent="center"
          // alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
           <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left" }}>
            Financeable
          </Typography>
          <br />
          <br /> 
          <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}> 
            This is it.
          </Typography>
          <br />
          <br />
          <Typography variant="h3">
            The last finance platform you'll ever sign up for.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
