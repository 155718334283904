import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

import {
  Editor,
  // MUIEditorState,
  // toolbarControlTypes,
} from "react-draft-wysiwyg";

import {
  saveValue,
  userSelector,
  updateSettings,
} from "src/store/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  LANG_PREFIX,
} from "draft-js";
import { debounce } from "lodash";

// const rawContent = {
//   blocks: [
//     {
//       data: {},
//       depth: 0,
//       entityRanges: [],
//       inlineStyleRanges: [],
//       key: "2vm6d",
//       text: "[Type/paste your privacy template here...]",
//       type: "normal",
//       color: "#bbbbbb",
//     },
//   ],
//   entityMap: {},
// };

// const config = {
//   toolbar: {
//     // className: "MuiPaper-elevation",
//     // wrapperElement: "div",
//     // psotion: "bottom",
//     style: {
//       boxShadow: "none",
//       // borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
//       borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
//       borderRadius: 0,
//       // position: "fixed",
//       // bottom: "0",
//       // width: "300px",
//       background: "#fff",
//       zIndex: 1000000,
//     },
//     controls: [
//       toolbarControlTypes.bold,
//       toolbarControlTypes.italic,
//       toolbarControlTypes.underline,
//       toolbarControlTypes.undo,
//       toolbarControlTypes.redo,
//       toolbarControlTypes.blockType,
//       toolbarControlTypes.fontSize,
//       toolbarControlTypes.textAlign,
//       toolbarControlTypes.unorderedList,
//       toolbarControlTypes.orderedList,
//     ],
//     controlsConfig: {
//       blockType: {
//         options: [
//           {
//             value: "default",
//             text: "normal",
//           },
//           {
//             value: "header-one",
//             text: "header1",
//           },
//           {
//             value: "header-two",
//             text: "header 1",
//           },
//           {
//             value: "header-three",
//             text: "header 2",
//           },
//           // {
//           //   value: 'blockquote',
//           //   text: 'Quote',
//           // },
//         ],
//       },
//     },
//   },
//   editor: {
//     wrapperElement: "div",
//     style: {
//       // borderRadius: "4px",
//       marginTop: 0,
//       // border: "1px solid rgba(0, 0, 0, 0.15)",
//     },
//   },
// };

export default function PrivacyTemplate() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const { templateSaveSuccess } = useSelector(userSelector);

  const [isSavedText, setIsSavedText] = useState("");

  const privacyTemplate = user?.settings?.privacyTemplate;

  console.log(privacyTemplate);

  // if (notes) rawContent = notes;

  // const [editorState, setEditorState] = useState(
  //   MUIEditorState.createWithContent(
  //     config,
  //     convertFromRaw(privacyTemplate || rawContent)
  //   )
  // );

  // const [rawText, setRawText] = useState(0);

  // const onChange = (newState) => {
  //   if (JSON.stringify(editorState) !== JSON.stringify(newState)) {
  //     setRawText(newState);
  //     setEditorState(newState);
  //     setIsSavedText("Editing...");
  //   }
  //   // console.log(newState);
  // };

  // const debounced = debounce(async () => {
  //   const blocks = convertToRaw(editorState.getCurrentContent());
  //   const contentState = editorState.getCurrentContent();
  //   const entityMap = contentState.getEntityMap();
  //   console.log(blocks, entityMap);
  //   setIsSavedText("Saving changes...");
  //   dispatch(
  //     updateSettings({
  //       privacyTemplate: { ...blocks, entityMap },
  //       _id: user?.settings?._id,
  //     })
  //   );
  // }, 2000);

  // useEffect(() => {
  //   if (rawText !== 0) debounced(editorState);

  //   return () => debounced.cancel();
  // }, [rawText]);

  // useEffect(() => {
  //   if (templateSaveSuccess === true) {
  //     setIsSavedText("Changes saved");

  //     dispatch(saveValue({ templateSaveSuccess: false }));
  //   }
  // }, [templateSaveSuccess]);

  // useEffect(() => {
  //   setEditorState(
  //     MUIEditorState.createWithContent(
  //       config,
  //       convertFromRaw(privacyTemplate || rawContent)
  //     )
  //   );
  // }, [privacyTemplate]);

  return (
    <>
      <Box
        style={{ border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "4px" }}
      >
        <Editor
        // editorState={editorState}
        // onChange={onChange}
        // config={config}
        />
      </Box>
      <Typography style={{ textAlign: "right", fontSize: "12px" }}>
        {isSavedText}
      </Typography>
    </>
  );
}
