import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import { saveValue, userSelector, getUser } from "../store/slices/userSlice";

import regex from "../utils/regex";

import GoogleAddressSearch from "./GoogleAddressSearch";
import {
  updateAddress,
  updateEmail,
  updatePhoneNumber,
} from "src/store/slices/organisationSlice";

export default function UserProfile() {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const { organisation } = user;

  // console.log("user", user);

  const [email, setEmail] = useState(organisation?.email?.address || "");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(" ");
  const [phoneNumber, setPhoneNumber] = useState(
    organisation?.phoneNumber?.number
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState(" ");

  const [address, setAddress] = useState(
    organisation?.address || ""
  );

  function handleEmail(event) {
    setEmail(event.target.value.trim());

    if (regex.email.test(event.target.value.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));
      return setEmailErrorText(" ");
    }

    setEmailError(true);
    setEmailErrorText("Invalid email");
  }

  function handlePhoneNumber(event) {
    setPhoneNumber(event.target.value);
  }

  async function handleUpdatePhoneNumber() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      updatePhoneNumber({
        phoneNumber,
        organisationId: organisation._id,
      })
    ).unwrap();
  }

  async function handleUpdateEmail() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      updateEmail({
        email,
        organisationId: organisation._id,
      })
    ).unwrap();
  }

  const handleUpdateAddress = (newAddressValue) => {
    // console.log("update address", newAddressValue);
    if (newAddressValue) {
      dispatch(
        updateAddress({
          address: newAddressValue,
          organisationId: organisation._id,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="center"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Paper style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              fontWeight="800"
              style={{ letterSpacing: "-1.2px" }}
            >
              Organisation profile
            </Typography>
            {/* <br />
          <br /> */}
            {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
            <br />
            {/* <br /> */}

            <br />

            {/* <Typography style={{ textAlign: "left", marginBottom: "10px" }}>
              Organisation details
            </Typography> */}
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "" }}
            >
              Name
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "0 0 20px" }}
            >
              {organisation?.name}
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "" }}
            >
              ABN
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "0 0 20px" }}
            >
              {organisation?.abn}
            </Typography>

            <TextField
              fullWidth
              // id="outlined-basic"
              type="email"
              name="email"
              label="Email"
              // autocomplete="email"
              // variant="filled"
              // variant="outlined"
              size="small"
              value={email}
              onChange={handleEmail}
              margin="dense"
              helperText={emailErrorText}
              error={emailError}
              onBlur={handleUpdateEmail}
              inputProps={{ maxLength: 50 }}
            />

            <TextField
              fullWidth
              // id="outlined-basic"
              type="tel"
              name="phone number"
              label="Phone number"
              autoComplete="tel"
              // variant="filled"
              // variant="outlined"
              size="small"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              margin="dense"
              helperText={phoneNumberErrorText}
              error={phoneNumberError}
              inputProps={{ maxLength: 15 }}
              onBlur={handleUpdatePhoneNumber}
            />

            <GoogleAddressSearch
              size="small"
              handleCreateAddress={handleUpdateAddress}
              address={address}
            />
          </Paper>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          height="100vh"
          justifyContent="center"
          // alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
           <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left" }}>
            Financeable
          </Typography>
          <br />
          <br /> 
          <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}> 
            This is it.
          </Typography>
          <br />
          <br />
          <Typography variant="h3">
            The last finance platform you'll ever sign up for.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
