import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, userSelector } from "../store/slices/userSlice";
import _ from "lodash";
import { Typography } from "@mui/material";

const successPage = new RegExp("request-success");
const failPage = new RegExp("request-fail");

const SuccessContent = () => {
  return (
    <>
      <Typography>
        Request to join your organisation has been successful. You may close
        this window.
      </Typography>
    </>
  );
};

const FailContent = () => {
  return (
    <>
      <Typography>
        Request to join your organisation has failed. We have let the user know
        to try again. You may close this window.
      </Typography>
    </>
  );
};

export default function AcceptRequestToJoin() {
  const location = useLocation();

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        {location.pathname.match(successPage) && <SuccessContent />}
        {location.pathname.match(failPage) && <FailContent />}
      </Grid>
    </>
  );
}
