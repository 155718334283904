import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import parse from 'autosuggest-highlight/parse';
import throttle from "lodash/throttle";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps({ handleCreateAddress, address, size }) {
  const [value, setValue] = React.useState(address?.fullAddress);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyByJ8mHpDMsQYWH-ToZE5eLBvEouildEYs&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    setValue(address?.fullAddress)
  }, [address])

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      blurOnSelect={true}
      autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={value}
      error={!value}
      helperText={!value ? "Required" : ""}
      value={address ? address.fullAddress : ""}
      // inputValue={inputValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);

        console.log("newValue", newValue);
        setValue(newValue);
        handleCreateAddress(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        console.log(newInputValue)
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          error={!address || !inputValue}
          helperText={!address ? "Required" : ""}
          {...params}
          // value={address ? address.fullAddress : ""}
          label="Address"
          fullWidth
          size={size}
          inputProps={{
            ...params.inputProps,
            autoComplete: "none"
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
