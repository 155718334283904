import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, resetPassword } from "../store/slices/userSlice";
import _ from "lodash";
import { Typography } from "@mui/material";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { key } = useParams();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSame, setIsSame] = useState(null);

  const user = useSelector(userSelector);

  async function handleNewPassword(event) {
    setNewPassword(event.target.value);
  }

  async function handleConfirmNewPassword(event) {
    setConfirmNewPassword(event.target.value);

    if (event.target.value === newPassword) return setIsSame(true);

    return setIsSame(false);
  }

  async function handleRequestResetPassword() {
    await dispatch(resetPassword({ newPassword, key })).unwrap();
  }

  // TODO: password validate function

  useEffect(() => {
    console.log(user);
    if (user?._id) navigate("/dashboard/applications");
  }, [user]);

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          // height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="left"
          style={{ padding: "100px" }}
          // spacing={1}
        >
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Welcome back!
          </Typography>
          {/* <br /> */}
          <Typography
            // variant="body1"
            fontWeight="400"
            style={{ marginBottom: "10px" }}
          >
            Sign into your account below.
          </Typography>

          <TextField
            fullWidth
            // id="outlined-basic"
            type="password"
            name="password"
            label="Password"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={newPassword}
            margin="dense"
            onChange={handleNewPassword}
            // onBlur={() => {}}
          />
          <TextField
            fullWidth
            // id="outlined-basic"
            type="password"
            name="password"
            label="Password"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={confirmNewPassword}
            margin="dense"
            onChange={handleConfirmNewPassword}
            error={isSame === false}
            helperText={isSame === false && "Your passwords do not match."}
            // onBlur={() => {}}
          />
          <LoadingButton
            fullWidth
            // endIcon={<SendOutlinedIcon />}
            loading={""}
            // loadingIndicator="Generating..."
            loadingPosition="end"
            variant="contained"
            disabled={!isSame}
            onClick={handleRequestResetPassword}
            style={{ marginTop: "20px" }}
          >
            Login
          </LoadingButton>

          {/* <Snackbar
          open={submitSuccess}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSubmitSuccess(false)}
          // message="Application submitted"
          // action={action}
        >
          <Alert
            onClose={() => setSubmitSuccess(false)}
            elevation={6}
            severity="success"
            sx={{ width: "100%" }}
          >
            Error
          </Alert>
        </Snackbar> */}
        </Grid>
      </Grid>
    </>
  );
}
