import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, userSelector } from "../store/slices/userSlice";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactSignatureCanvas from "react-signature-canvas";
import { borderRadius, Box } from "@mui/system";
import { submitPrivacy } from "src/store/slices/applicationFormSlice";

export default function Privacy() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let sigCanvas = useRef()
  const [signature, setSignature] = useState()
  const [submitted, setSubmitted] = useState(false)

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log({ params });
  const customerId = params.cid;
  const applicationId = params.aid;

  function trimSignature() {
    setSignature(sigCanvas.getTrimmedCanvas()
      .toDataURL('image/jpg'))
  }

  async function handleSubmitPrivacy() {
    await dispatch(submitPrivacy({ customerId, applicationId, signature })).unwrap()
    setSubmitted(true)
  }

  return (
    <>
      <Grid container item xs={12} style={{ padding: "30px 30px 100px" }} >
        {!submitted && <>
          <Grid container item xs={12} justifyContent="center">
            <Grid item direction="column" sm={12} md={6} >
              <Typography variant="h1" style={{ fontSize: "24px", marginBottom: "20px" }}>PRIVACY DISCLOSURE STATEMENT & CONSENT</Typography>
              <Typography variant="h2" style={{ fontSize: "18px", marginBottom: "50px" }}>Please read the statement and sign at the bottom before submitting.</Typography>

              <Accordion style={{ marginBottom: "40px", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ padding: "5px 30px" }}
                >
                  <Typography>Show statement</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "20px 30px" }}>

                  <Typography variant="h2" style={{ fontSize: "18px", marginBottom: "10px" }}>GROUP & GENERAL FINANCE PTY LTD</Typography>

                  <Typography variant="h3" style={{ fontSize: "16px", marginBottom: "40px", fontWeight: 700 }}>
                    Ph: (02) 8817 7780 ABN: 87 000 903 257 Australian Credit License: 387822

                  </Typography>

                  <Typography variant="h3" style={{ fontSize: "18px", marginBottom: "20px" }}>Overview</Typography>

                  <Typography variant="body2" style={{ fontSize: "16px", marginBottom: "20px" }}>
                    Group & General Finance Pty Ltd, ACN 000 937 257, (‘we’, ‘us’, ‘our’)
                    collects information about you for the purposes you agree to in this Privacy
                    Disclosure Statement and Consent. When you sign below, you agree we can,
                    consistently with Australia’s privacy and credit reporting laws, collect,
                    use and exchange credit and personal information about you for those
                    purposes.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}>Privacy Disclosure Statement and Consent</Typography>
                  <Typography style={{ marginBottom: "5px" }}>
                    We are collecting credit and personal information (information) about you,
                    as applicable:
                  </Typography>
                  {/* <Typography> */}
                  <ul style={{ listStyle: "inside" }}>
                    <li>
                      To provide you, or a company of which you are a director, with:
                      <ul>
                        <li style={{ marginLeft: "20px" }}>
                          credit for personal, household, domestic or residential investment
                          purposes; or Commercial credit for business purposes; or Other
                          services stated in this Privacy Disclosure Statement and Consent
                          (Consent); or
                        </li>
                      </ul>
                    </li>
                    <li>In relation to a guarantee you will provide.</li>
                  </ul>
                  {/* </Typography> */}

                  <Typography style={{ marginBottom: "20px" }}>
                    We require the information we collect from you, or your broker on your
                    behalf, to assess your credit or guarantor application or the credit
                    application of a company of which you are a director, source any required
                    insurances and to manage any credit contract that results. If you do not
                    provide the information sought we may be unable to process your application,
                    or the company’s application, or we may be limited in the other services we
                    can offer you or the company.
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    We are also required to collect your personal information to comply with our
                    obligations under Australian law, including the
                    <em
                    >Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth).</em
                    >
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}>
                    Your information – Collection and Credit Reporting Body Disclosures
                  </Typography>
                  <Typography style={{ marginBottom: "5px" }}>
                    When we collect information from you in the credit application process, we
                    use that information in a number of ways to assess your credit application
                    and to manage any credit contract that results. We may:
                  </Typography>
                  <Typography>
                    <ul style={{ listStyle: "inside" }}>
                      <li>
                        Disclose your information to Veda Advantage Information Services and
                        Solutions Limited, a credit reporting body (CRB)
                      </li>
                      <li>
                        Use information the CRB provides to assist us assess your credit or
                        guarantor application
                      </li>
                      <li>
                        Disclose your credit information to your introducer to assist in the
                        application process
                      </li>
                      <li>
                        Notify the CRB of any overdue payments, provided they are more than 60
                        days overdue, we have attempted to collect the payment and we have
                        notified you of our intention to do so
                      </li>
                      <li>
                        Notify the CRB of a serious credit infringement if we have reasonable
                        grounds to believe your fraudulently obtained, or attempted to obtain,
                        credit from us or that you have shown an intention to evade your
                        obligations under the contract with us
                        <ul>
                          <li style={{ marginLeft: "20px" }}>
                            We will only do this if we have not been able to contact you over a 6
                            month period
                          </li>
                        </ul>
                      </li>
                      <li>
                        Ask the CRB to assess your eligibility to receive direct marketing
                        material from us
                      </li>
                      <li>
                        Disclose your credit and personal information to any person we are
                        authorised or required to by law, such as under the Anti-Money Laundering
                        and Counter-Terrorism Financing Act 2006 (Cth)or by government or law
                        enforcement agencies or regulators, or where you have provided us with
                        consent.
                      </li>
                    </ul>
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    The CRB may include your information in reports to other credit providers to
                    assist them in assessing your credit worthiness.
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    The information we obtain from you is used, subject to compliance with
                    Australia’s privacy and credit reporting laws, only for the purposes listed
                    in this Consent and is not disclosed to any other person except with your
                    permission or as permitted, or required, by law.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}>Your rights</Typography>
                  <Typography style={{ marginBottom: "5px" }}>You have the right to ask:</Typography>
                  <Typography>
                    <ul style={{ marginBottom: "20px", listStyle: "inside" }}>
                      <li>us to provide you with all the information we hold about you</li>
                      <li>us to correct the information we hold if it is incorrect</li>
                      <li>
                        us for copies of our privacy policy and this document, in a form that
                        suits you (e.g. hardcopy or email)
                      </li>
                      <li>
                        the CRB not to use your information for direct marketing assessment
                        purposes, including pre-screening
                      </li>
                      <li>
                        the CRB not to use, or disclose, your information if you have reasonable
                        grounds to believe you have, or will be, a fraud victim
                      </li>
                      <li>
                        the CRB to provide you with a copy of the information it holds about you
                      </li>
                    </ul>
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    You can gain access to the information we hold about you by contacting our
                    Privacy Officer by telephone on 02 9418 5513 or email at
                    accounthelp@alto.com.au. In some cases an administration fee may be charged
                    to cover the cost of providing the information.
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    Our Privacy Policy is available on our website at
                    http://www.alto.com.au/headoffice/alto-headoffice/alto-auto-group-car-dealer-general/
                    or we will provide you with a copy if you ask us.
                  </Typography>

                  <Typography style={{ marginBottom: "20px" }}>
                    You can contact the CRB by email at membership.correction@veda.com.au or
                    visit their website www.veda.com.au
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}>Disclosure and Consent</Typography>
                  <Typography style={{ marginBottom: "5px" }}>By signing below, you agree we may:</Typography>
                  <Typography>
                    <ul style={{ marginBottom: "20px", listStyle: "inside" }}>
                      <li>
                        Use your personal and credit information:
                        <ul>
                          <li style={{ marginLeft: "20px" }}>
                            To assess your consumer or commercial credit and/or guarantee
                            application and/or to assess a credit application by a company of
                            which you are a director
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            To manage or better service your, or the company’s, account and any
                            future needs
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            To assist you to manage your debts or the company’s debts or collect
                            any amounts you or the company owes
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            As the law authorises or requires; any third party, including your
                            introducer, information about you, the applicant(s) or guarantor(s)
                            that is reasonably necessary to assess, arrange, provide or administer
                            your credit application or guarantee and any insurance requirements;
                          </li>
                        </ul>
                      </li>
                      <li>
                        Provide credit information about you to a guarantor, or prospective
                        guarantor;
                        <ul>
                          <li style={{ marginLeft: "20px" }}>
                            Provide you, or the company of which you are a director, with offers
                            or information of other goods or services we, or any of our associated
                            entities, may be able to provide to you or the company, unless you
                            tell us not to;
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Disclose your personal and credit information to other organisations
                            that provide us with services, such as contractors, agents, printers,
                            mail houses, lawyers, document custodians, securitisers and computer
                            systems consultants or providers, so they can perform those services
                            for us;
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Disclose your personal information to any other organisation that may
                            wish to acquire, or has acquired, an interest in our business or any
                            rights under your contract with us, or the contract with us of a
                            company of which you are a director: and
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Disclose your personal and credit information to a Document
                            Verification Service (DVS) to be subject to an Information Match
                            Request in relation to relevant Official Record Holder information.
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            The Information Match Request, the Information Match Result and other
                            Information Match Data and the use of the DVS may involve the use of
                            third party systems and that information as relevant will be
                            transmitted to New Zealand by the DVS.
                          </li>
                        </ul>
                      </li>
                      <li>
                        You also agree that we may, as appropriate:
                        <ul>
                          <li style={{ marginLeft: "20px" }}>
                            Where you are a consumer credit applicant, notify a CRB you as an
                            individual (not as a director of a company) have applied for credit
                            with us, the amount of the credit required, the contract details,
                            including the amount financed, your status under the contract
                            (borrower / co-borrower / guarantor) and commencement and termination
                            dates;
                          </li>
                          <li style={{ marginLeft: "20px" }}>Provide your identification details to the CRB</li>
                          <li style={{ marginLeft: "20px" }}>
                            Exchange your credit information with the CRB, any credit provider
                            named in your credit report or in your application and with any
                            introducer assisting you with the application
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Obtain information about your consumer credit or commercial
                            activities, or commercial credit worthiness, where relevant, from a
                            CRB;
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Disclose credit information about you to a guarantor, or a prospective
                            guarantor; and
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            When you are a prospective guarantor, obtain a credit report about you
                            to assess whether to accept you as a guarantor.
                          </li>
                        </ul>
                      </li>
                      <li>
                        You also agree and consent to, as appropriate:
                        <ul>
                          <li style={{ marginLeft: "20px" }}>
                            A CRB disclosing consumer credit information to us for considering
                            your consumer or commercial credit or guarantor application,
                            application by a company of which you are a director and/or assessment
                            a credit
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            When you are a prospective guarantor, us using that information to
                            assess your suitability as a guarantor Us disclosing your credit
                            information to a guarantor, or a prospective guarantor
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Another credit provider, disclosing to us for a particular purpose,
                            information it holds about you Where the applicant, or guarantor, is a
                            company of
                          </li>
                          <li style={{ marginLeft: "20px" }}>
                            Where the applicant, or guarantor, is a company of which you are a
                            director, you specifically acknowledge and agree you consent to the
                            use of your information, in addition to the company’s information, in
                            each of the ways listed above.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Typography>

                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="center" >
            <Grid item sm={12} md={6}>
              <Typography style={{ marginBottom: "30px", fontSize: "18px", fontWeight: 700 }}>
                I confirm that I am authorised to provide the personal details presented and
                I consent to my information being checked with the document issuer or
                official record holder for the purpose of confirming my identity.
              </Typography>
            </Grid>
          </Grid>
          {/* <Typography><span style={{ fontWeight: 700 }} id="sign"></span></Typography> */}
          <Grid container item xs={12} justifyContent="center">
            <Grid item sm={12} md={6}>

              <Box style={{
                margin: "0 0 20px 0",
                dotSize: 1,
                // padding: "0 15px",
                // width: "500px",
                // borderBottom: "2px solid #333333",
                zIndex: 10,
                position: "relative",
                // fontWeight: 700
              }}>Use the signing pad below to add your signature to accept the privacy statement and consent:</Box>

              <ReactSignatureCanvas
                ref={ref => sigCanvas = ref}
                penColor='#0a66b7'
                onEnd={() => trimSignature()}
                canvasProps={{
                  className: 'sigCanvas',
                  style: {
                    width: "100%",
                    // height: 180,
                    aspectRatio: "2/1",
                    position: "relative",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "8px"
                  }
                }} />
              <Box style={{
                margin: "-30px auto 20px",
                padding: "0 15px",
                width: "95%",
                borderBottom: "2px solid #333333",
                zIndex: 10,
                position: "relative",

              }}>
                {/* <Typography style={{ fontSize: "32px", userSelect: "none" }}>x</Typography> */}
              </Box>
              <Button variant="link" onClick={() => sigCanvas.clear()} style={{ float: "right" }}>Clear signature</Button>
              <Grid item sm={12}>
                <LoadingButton
                  fullWidth
                  disabled={!signature}
                  variant="contained"
                  onClick={() => handleSubmitPrivacy()}
                  // onClick={() => createPrivacy({ customerId, applicationId, signature: sigCanvas.toDataUrl() })}
                  style={{ marginTop: "50px" }}
                >
                  Accept Privacy Agreement
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </>}

        {submitted && <Grid container item direction="column" xs={12}>
          <Typography
            variant="h1"
            style={{
              fontSize: "24px",
              marginBottom: "20px"
            }}>
            Privacy Agreement
          </Typography>
          <Typography
            variant="h2"
            style={{
              fontSize: "18px",
              marginBottom: "50px"
            }}
          >
            Thank you for signing and submitting the Privacy Agreement.
          </Typography>
          <Typography
            // variant="h2"
            style={{
              fontSize: "18px",
              // marginBottom: "50px"
            }}
          >
            You may close this window.
          </Typography>
        </Grid>
        }

      </Grid>
    </>
  );
}
