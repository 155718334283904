import axios from "axios";
import parseJSONP from "../utils/parseJSONP";

const GUID = process.env.ABR_TOKEN || "4f94b581-a15b-4345-967c-b349343a23e6";

export async function getBusinessByABN(searchTerm) {
  try {
    const response = await axios({
      method: "get",
      url: "https://abr.business.gov.au/json/AbnDetails.aspx",
      params: {
        guid: GUID,
        abn: `${searchTerm}`,
      },
    });

    return parseJSONP(response.data);
  } catch (error) {
    console.error(error);
  }
}

export async function getBusinessByACN(searchTerm) {
  try {
    const response = await axios({
      method: "get",
      url: "https://abr.business.gov.au/json/AcnDetails.aspx",
      params: {
        guid: GUID,
        acn: searchTerm,
      },
    });

    return parseJSONP(response.data);
  } catch (error) {
    console.error(error);
  }
}

export async function getBusinessByName(searchTerm) {
  try {
    const response = await axios({
      method: "get",
      url: "https://abr.business.gov.au/json/MatchingNames.aspx",
      params: {
        guid: GUID,
        name: searchTerm,
      },
    });

    return parseJSONP(response.data);
  } catch (error) {
    console.error(error);
  }
}
