import criteria from "src/utils/criteria";
import { livingArrangementsTypes } from "src/constants";
const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes

const {
  livingArrangements,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountGreaterThan,
  loanAmountBetween,
  timeInBusinessBetween,
  timeInBusinessLessThan,
  creditScoreGreaterThan
} = criteria;

export default {
  date: "29 May 2023",
  lender: "Group & General Finance",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FG%26G%2Blogo.gif",
  fees: [
    {
      name: "Origination fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Document fee",
      value: 649,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 250000)],
    },
  ],
  loading: [
    {
      name: "Non property owner",
      value: 2,
      valueType: "percentage",
      criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    },
    {
      name: "Private sale",
      value: 0.75,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Mid term refinance",
      value: 1.5,
      valueType: "percentage",
      criteria: [typeOfSale("Mid-term refinance")],
    },
    {
      name: "Sale & buyback / Equity",
      value: 1.5,
      valueType: "percentage",
      criteria: [typeOfSale("Sale & buyback / Equity")],
    },
    {
      name: "ABN <12 months",
      value: 2.50,
      valueType: "percentage",
      criteria: [timeInBusinessLessThan(12)],
    },
    {
      name: "ABN <24 months",
      value: 1.50,
      valueType: "percentage",
      criteria: [timeInBusinessBetween(12, 24)],
    },
    {
      name: "Loan amount >$150k",
      value: 0.5,
      valueType: "percentage",
      criteria: [loanAmountGreaterThan(150000)],
    },
  ],
  info: [
    `Bankstatements required for all applications`,
    `Defaults have to be paid`,
    `ATO arrears - may have a rate load of 1-2% (GGF discretion)`,
    `Hire / Rental / UBER / Courier may have a rate load - 1-4% (GGF discretion)`,
    `Max term 60 mths`,
    `Max balloons/residuals 30% (restricted to <24mths age at start)`
  ],
  productTiers: [
    {
      name: "Light doc",
      rate: 7.19,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThan(36),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 7.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(36, 72),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 8.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(72, 120),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 9.09,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(120, 168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 9.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetGreaterThan(168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },

    {
      name: "Light doc",
      rate: 7.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThan(48),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 8.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 8.99,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 9.69,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc",
      rate: 10.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },


    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 8.29,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThan(48),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 8.89,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 9.49,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 10.19,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 10.89,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([PROPERTY_OWNER])
      ],
    },







    {
      name: "Light doc",
      rate: 7.19,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThan(36),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 7.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(36, 72),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 8.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(72, 120),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 9.09,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(120, 168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 9.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetGreaterThan(168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },

    {
      name: "Light doc",
      rate: 7.79,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThan(48),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 8.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 8.99,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 9.69,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc",
      rate: 10.39,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },


    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 8.29,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThan(48),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 8.89,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 9.49,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 10.19,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },
    {
      name: "Light doc - Mini Buses & Route Buses (<25 seats)",
      rate: 10.89,
      productType: "Light doc",
      info: [
        `100k-150K ATO Portal MAY be required (6 mths)`
      ],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])
      ],
    },








    {
      name: "Full doc",
      rate: 7.19,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetLessThan(36),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 7.79,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(36, 72),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 8.39,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(72, 120),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 9.09,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(120, 168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 9.79,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetGreaterThan(168),
        assetType(["Passenger/light commercial vehicles"]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Full doc",
      rate: 7.79,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetLessThan(48),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 8.39,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 8.99,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 9.69,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 10.39,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Trucks/trailers",
          "Wheeled/tracked machinery",
          "Buses/people carriers"
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },


    {
      name: "Full doc",
      rate: 8.29,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetLessThan(48),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 8.89,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(36, 72),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 9.49,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(72, 120),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 10.19,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetBetween(120, 168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 10.89,
      productType: "Full doc",
      info: [
        `>150K - ATO portal is mandatory (6 mths)`
      ],
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetGreaterThan(168),
        assetType([
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        ageOfAssetAtEndLessThan(30),
        creditScoreGreaterThan(400)
      ],
    },
  ],
};