import { useEffect, useRef, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Grid,
  TextField,
  Slider,
  Stack,
  Divider,
  Skeleton,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// import io from 'socket.io-client';
import {
  updateCustomer,
  userDetailsSelector,
  getCustomer,
  sendPrivacyLink,
  sendBankStatementsLink,
  saveCustomerProfile,
  getDocuments,
  saveValue,
  addAddress,
  uploadDocument,
  updateCreditProfile,
  applicationSelector,
  saveCustomer,
} from "../store/slices/applicationFormSlice";
import PhoneNumber from "./PhoneNumber";
import Email from "./Email";
import Identity from "./Identity";
import RadioButtonGroup from "./RadioButtonGroup";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
// import Income from "./Income";
// import Expenses from "./Expenses";
import Document from "./Document";
import GoogleAddressSearch from "./GoogleAddressSearch";
import usePoll from "src/utils/usePoll";
import { styled } from "@mui/styles";

const Input = styled("input")({
  display: "none",
});

export default function CustomerDetails({ customer, index }) {
  const dispatch = useDispatch();
  let application = useSelector(applicationSelector);

  // const state = useSelector(userDetailsSelector);

  // console.log("BOBBBBBB");
  // console.log({ customer });

  // const CUSTOMER = customer;

  const {
    privacyLoading = "",
    bankStatementsLoading = "",
    uploadPrivacyLoading = "",
    documentUploadLoading = "",
    uploadBankstatementsLoading = ""
  } = useSelector(userDetailsSelector);

  const [firstName, setFirstName] = useState(customer.firstName || "");
  const [middleNames, setMiddleNames] = useState(customer.middleNames || "");
  const [lastName, setLastName] = useState(customer.lastName || "");
  const [licenceNumber, setLicenceNumber] = useState(customer.IDNumber || "");
  const [dateOfBirth, setDateOfBirth] = useState(customer.dateOfBirth || "");

  const [illionReferralID, setIllionRefferalID] = useState(customer?.creditProfile?.illionReferralID);
  const [bankstatementsDocID, setBankstatementsDocID] = useState(customer?.creditProfile?.bankstatementsDocID);

  const [documentName, setDocumentName] = useState("");

  const [privacyButtonBackground, setPrivacyButtonBackground] =
    useState("secondary");
  const [privacyButtonText, setPrivacyButtonText] =
    useState("Send privacy link");

  const [uploadPrivacyButtonBackground, setUploadPrivacyButtonBackground] =
    useState("secondary");
  const [uploadPrivacyButtonText, setUploadPrivacyButtonText] = useState(
    "Upload Privacy Agreement"
  );

  const [bankStatementsUploadButtonBackground, setBankStatementsUploadButtonBackground] =
    useState("secondary");
  const [bankStatementsUploadButtonText, setBankStatementsUploadText] =
    useState("Upload Bank statements");

  const [uploadDocumentButtonBackground, setUploadDocumentButtonBackground] =
    useState("secondary");
  const [uploadDocumentButtonText, setUploadDocumentButtonText] =
    useState("Upload Document");

  const [bankStatementsButtonBackground, setBankStatementsButtonBackground] = useState("primary")
  const [bankStatementsButtonText, setBankStatementsButtonText] = useState("Send Bank Statements Link")

  const [count, setCount] = useState(0);
  const [delay, setDelay] = useState(null);

  const [imageWidth, setImageWidth] = useState();
  const ref = useRef(null);

  const [sameAsGuarantorAddress, setSameAsGuarantorAddress] =
    useState(customer?.addresses?.[customer.addresses.length - 1]?.fullAddress ===
      application?.customers?.[0]?.addresses?.[application?.customers?.[0]?.addresses.length - 1]?.fullAddress);

  useEffect(() => {
    console.log("width", ref.current ? ref.current.offsetWidth : 0);
    setImageWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  // console.log("customer", customer);

  function handleSaveCustomer(data) {
    console.log({ data })
    dispatch(
      updateCustomer({
        customerId: customer._id,
        data: {
          application: customer.application,
          ...data,
        },
      })
    );
  }

  function handleSendBankStatementsLink(customerId, applicationId, mobile) {
    dispatch(saveValue({ bankStatementsLoading: true }));
    dispatch(sendBankStatementsLink({ customerId, applicationId, mobile }));
  }

  function handleSendPrivacyLink(customerId, applicationId, mobile) {
    dispatch(saveValue({ privacyLoading: true }));
    dispatch(sendPrivacyLink({ customerId, applicationId, mobile }));
  }

  function handleGetCustomer(customerId) {
    console.log("getting customer");
    if (customerId) {
      dispatch(getCustomer(customerId));
    }
  }

  function handleLivingArrangements(value) {
    dispatch(
      saveCustomerProfile({
        livingArrangements: value,
        customer: customer._id,
      })
    );
    // dispatch(
    //   saveCustomer({
    //     customerProfile: { livingArrangements: value },
    //     customerId: customer._id,
    //   })
    // );
  }

  function handleNumberOfDependants(event, value) {
    dispatch(
      saveCustomerProfile({
        numberOfDependants: value,
        customer: customer._id,
      })
    );
  }

  function handlePrivacyButtonSuccessStyle() {
    setPrivacyButtonBackground("success");
    setPrivacyButtonText("link sent");

    setTimeout(() => {
      setPrivacyButtonBackground("secondary");
      setPrivacyButtonText("Send privacy link");
    }, 6000);
  }

  function handleBankstatementsButtonSuccessStyle() {
    setBankStatementsButtonBackground("success");
    setBankStatementsButtonText("link sent");

    setTimeout(() => {
      setBankStatementsButtonBackground("secondary");
      setBankStatementsButtonText("Send bank statements link");
    }, 6000);
  }

  function handleUploadBankstatementsButtonSuccessStyle() {
    setBankStatementsUploadButtonBackground("success");
    setBankStatementsUploadText("Bank statements uploaded");

    setTimeout(() => {
      setBankStatementsUploadButtonBackground("secondary");
      setBankStatementsUploadText("Upload bank statements");
    }, 6000);
  }

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        addAddress({
          customerId: customer._id,
          address: newAddressValue,
        })
      );
    }
  };

  function handleLicenceNumber() {
    return customer
      ? customer?.documents?.find((doc) => {
        return doc.details?.licence_number ? doc.details?.licence_number : "";
      })?.details?.licence_number
      : "";
  }

  function handleUploadPrivacy(event, customerId) {
    dispatch(saveValue({ uploadPrivacyLoading: true }));
    const formData = new FormData();
    console.log(event.target.files[0]);
    console.log("UPLOAD DETAILS");
    console.log(customerId);
    // console.log(CUSTOMER.firstName);
    // Update the formData object
    formData.append("file", event.target.files[0]);
    formData.append("applicationId", application._id);
    formData.append("customerId", customerId);
    formData.append("name", "Privacy agreement");

    // Details of the uploaded file

    dispatch(uploadDocument(formData));
    event.target.input = null;
    // Request made to the backend api
    // Send formData object
  }

  function handleUploadBankstatements(event, customerId) {
    dispatch(saveValue({ uploadBankstatementsLoading: true }));
    const formData = new FormData();
    console.log(event.target.files[0]);
    console.log("UPLOAD BANKSTATEMENTS");
    console.log(customerId);
    // console.log(CUSTOMER.firstName);
    // Update the formData object
    formData.append("file", event.target.files[0]);
    formData.append("applicationId", application._id);
    formData.append("customerId", customerId);
    formData.append("name", "Bank statements");

    // Details of the uploaded file

    dispatch(uploadDocument(formData));
    event.target.input = null;
    // Request made to the backend api
    // Send formData object
  }

  function handleUploadDocument(event, label, customerId) {
    dispatch(saveValue({ documentUploadLoading: true }));
    const formData = new FormData();
    console.log(event.target.files[0]);
    console.log("UPLOAD DETAILS");
    console.log(customerId);
    // console.log(CUSTOMER.firstName);
    // Update the formData object
    formData.append("file", event.target.files[0]);
    formData.append("applicationId", application._id);
    formData.append("customerId", customerId);
    formData.append("name", label);

    // Details of the uploaded file

    dispatch(uploadDocument(formData));
    event.target.input = null;
    // Request made to the backend api
    // Send formData object
  }

  function handleUploadPrivacyButtonSuccessStyle() {
    setUploadPrivacyButtonBackground("success");
    setUploadPrivacyButtonText("Successfully uploaded");

    setTimeout(() => {
      setUploadPrivacyButtonBackground("secondary");
      setUploadPrivacyButtonText("Upload Privacy Agreement");
      dispatch(
        getDocuments(
          customer._id
          // application: applicationId,
        )
      );
    }, 6000);
  }

  function handleUploadDocumentButtonSuccessStyle() {
    setUploadDocumentButtonBackground("success");
    setUploadDocumentButtonText("Successfully uploaded");

    setTimeout(() => {
      setUploadDocumentButtonBackground("secondary");
      setUploadDocumentButtonText("Upload Document");
      dispatch(
        getDocuments(
          customer._id
          // application: applicationId,
        )
      );
    }, 6000);
  }

  function handleAssetsRequired() {
    return customer?.customerAssets?.items
      ?.map((asset) => !!asset.value)
      .includes(true);
  }

  function handleSaveCreditProfile(data) {
    dispatch(updateCreditProfile({ customer: customer?._id, ...data }))
  }
  // const DateMaskCustom = forwardRef(function DateMaskCustom(props, ref) {
  //   const { onChange, ...other } = props;
  //   return (
  //     <IMaskInput
  //       {...other}
  //       mask={Date}
  //       inputRef={ref}
  //       onAccept={(value) => onChange({ target: { name: props.name, value } })}
  //       overwrite
  //     />
  //   );
  // });

  function handleSameAsGuarantorAddress(event) {
    setSameAsGuarantorAddress(event.target.checked)
    if (event.target.checked) handleAddAddress(application.customers?.[0]?.addresses[application.customers?.[0]?.addresses.length - 1])
  }

  useEffect(() => {
    if (uploadPrivacyLoading === false) handleUploadPrivacyButtonSuccessStyle();
  }, [uploadPrivacyLoading]);

  useEffect(() => {
    if (privacyLoading === false) handlePrivacyButtonSuccessStyle();
  }, [privacyLoading]);

  useEffect(() => {
    if (bankStatementsLoading === false) handleBankstatementsButtonSuccessStyle();
  }, [bankStatementsLoading]);

  useEffect(() => {
    if (uploadBankstatementsLoading === false)
      handleUploadBankstatementsButtonSuccessStyle();
  }, [documentUploadLoading]);

  useEffect(() => {
    if (documentUploadLoading === false)
      handleUploadDocumentButtonSuccessStyle();
  }, [documentUploadLoading]);

  useEffect(() => {
    if (customer.documents.filter((doc) => doc.name === "Privacy agreement")[0])
      setDelay(null);
  }, [customer]);

  usePoll(async () => {
    // setStartedCount((count) => count + 1);
    dispatch(
      getDocuments(
        customer._id
        // application: applicationId,
      )
    );
    // setFinishedCount((count) => count + 1);
  }, delay);

  return (
    <>
      <Grid container item xs={12} spacing={1} style={{ marginBottom: "30px" }}>
        <Grid item md={12}>
          <Typography id="input-slider" gutterBottom>
            Personal details
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <TextField
            id="outlined-basic"
            type="text"
            name="applicant_firstname"
            label="First name"
            variant="outlined"
            value={firstName || ""}
            error={!firstName}
            helperText={!firstName ? "Required" : ""}
            onChange={(event) => setFirstName(event.target.value)}
            onBlur={(event) =>
              handleSaveCustomer({ firstName: event.target.value })
            }
            inputProps={{
              autoComplete: "none"
            }}
          />
        </Grid>

        <Grid item md={4} sm={12}>
          <TextField
            id="outlined-basic"
            type="text"
            name="applicant_othername"
            label="Other given names"
            variant="outlined"
            value={middleNames || ""}
            onChange={(event) => setMiddleNames(event.target.value)}
            onBlur={(event) =>
              handleSaveCustomer({ middleNames: event.target.value })
            }
            inputProps={{
              autoComplete: "none"
            }}
          />
        </Grid>

        <Grid item md={4} sm={12}>
          <TextField
            id="outlined-basic"
            type="text"
            name="applicant_lastname"
            label="Last name"
            variant="outlined"
            value={lastName || ""}
            error={!lastName}
            helperText={!lastName ? "Required" : ""}
            onChange={(event) => setLastName(event.target.value)}
            onBlur={(event) =>
              handleSaveCustomer({ lastName: event.target.value })
            }
            inputProps={{
              autoComplete: "none"
            }}
          />
        </Grid>

        {/* <Grid item md={12} sm={12}>
          <Typography id="input-slider" gutterBottom>
            ID Number
          </Typography>
        </Grid> */}
        <Grid item md={4} sm={4}>
          <TextField
            autoComplete={false}
            id="outlined-basic"
            type="text"
            name="dob"
            label="Date of birth"
            variant="outlined"
            placeholder="DD-MM-YYYY"
            value={dateOfBirth || ""}
            error={!dateOfBirth}
            helperText={!dateOfBirth ? "Required" : ""}
            // InputProps={{
            //   inputComponent: DateMaskCustom,
            // }}
            onChange={(event) => {
              console.log(event.target.value)
              setDateOfBirth(event.target.value);
            }}
            onBlur={(event) =>
              handleSaveCustomer({ dateOfBirth: event.target.value })
            }
            inputProps={{
              autoComplete: "none"
            }}
          />
        </Grid>
        <Grid item md={4} sm={4}>
          <TextField
            fullWidth
            id="outlined-basic"
            type="text"
            name="applicant_address"
            label="Driver licence/Passport no."
            variant="outlined"
            error={!licenceNumber && !handleLicenceNumber()}
            helperText={!licenceNumber && !handleLicenceNumber() ? "Required" : ""}
            value={licenceNumber || handleLicenceNumber() || ""}
            onChange={(event) => setLicenceNumber(event.target.value)}
            onBlur={() => handleSaveCustomer({ IDNumber: licenceNumber })}
            inputProps={{
              autoComplete: "none"
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} style={{ marginBottom: "30px" }}>
        <Grid item md={12} sm={12}>
          <Typography id="input-slider" gutterBottom>
            Contact details
          </Typography>
        </Grid>

        <Grid item sm={4}>
          <Email
            customerId={customer?._id}
            email={
              customer
                ? customer?.emails.find((a) => a.status === "current" && a.address)
                : ""
            }
          />
        </Grid>
        <Grid item sm={4}>
          <PhoneNumber
            customerId={customer?._id}
            phoneNumber={
              customer
                ? customer?.phoneNumbers.find(
                  (a) => a.status === "current" && a.number
                )
                : ""
            }
          />
        </Grid>
        <Grid item sm={12}>
          <GoogleAddressSearch
            handleCreateAddress={handleAddAddress}
            address={
              customer
                ? customer?.addresses[customer.addresses.length - 1]
                : ""
            }
          />
          {index !== 0 &&
            <FormControlLabel
              checked={sameAsGuarantorAddress}
              onChange={handleSameAsGuarantorAddress}
              control={<Checkbox />}
              label="Same as primary guarantor"
            />}
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={3} style={{ marginBottom: "30px" }}>
        <Grid item md={12} sm={12}>
          <Typography>Personal Profile</Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item sm={6}>
            <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
              Property/Living arrangements
            </Typography>
            <Box style={{ padding: "0 7px" }}>
              <RadioButtonGroup
                livingArrangements={customer?.customerProfile?.livingArrangements}
                handleValue={handleLivingArrangements}
                options={[
                  { icon: "House", label: "Property owner" },
                  { icon: "HomeWork", label: "Renting" },
                  { icon: "Hotel", label: "Boarding" },
                ]}
              />
            </Box>
          </Grid>

          <Grid item sm={6}>

            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginBottom: "40px" }}
            >
              Number of dependents
            </Typography>
            <Box style={{ padding: "0 7px" }}>
              <Slider
                color="secondary"
                marks={[
                  { value: 0, label: 0 },
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 },
                  { value: 6, label: 6 },
                  { value: 7, label: 7 },
                  { value: 8, label: 8 },
                  { value: 9, label: 9 },
                  { value: 10, label: "10+" },
                ]}
                step={1}
                min={0}
                max={10}
                aria-label="Default"
                valueLabelDisplay="auto"
                // onChange={}
                defaultValue={customer.customerProfile?.numberOfDependants || 0}
                onChangeCommitted={handleNumberOfDependants}
              />
            </Box>

          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={2} style={{ marginBottom: "30px" }}>
        <Grid item md={12} sm={12}>
          <Typography>Personal Finances</Typography>
        </Grid>

        <Assets assets={customer.customerAssets} customerId={customer._id} index={index} />

        {/* <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: "-1px" }}
          /> */}
        <Liabilities
          liabilities={customer.liabilities}
          customerId={customer._id}
          index={index}
        />

        {/* <Income income={customer.income} customerId={customer._id} />

        <Expenses expenses={customer.expenses} customerId={customer._id} /> */}
      </Grid>

      <Grid item xs={12} style={{ marginBottom: "30px" }}>
        <Typography id="input-slider" style={{ marginBottom: "10px" }}>
          Privacy Agreement
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: "20px" }}
        // customer={customer}
        >
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")
              .map((doc) => (
                // <Grid ref={ref} item xs={4}>
                <Document
                  document={doc}
                  customer={customer}
                // width={imageWidth}
                />
                // </Grid>
              ))}
        </Stack>

        <Stack
          spacing={1}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <LoadingButton
            loading={privacyLoading}
            color={privacyButtonBackground}
            variant="outlined"
            // style={{ marginBottom: "5px" }}
            disabled={
              !customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
                ?.number
            }
            onClick={() => {
              console.log("phone:", customer);
              handleSendPrivacyLink(
                customer._id,
                application._id,
                customer?.phoneNumbers.filter((a) => a.status === "current")[0]
                  .number
              );
              dispatch(saveValue({ linkSent: true }));
              setDelay(30000);
            }}
          >
            {privacyButtonText}
          </LoadingButton>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          // customer={customer}
          >
            <label htmlFor={`upload-privacy-${customer._id}`}>
              <Input
                accept="*"
                id={`upload-privacy-${customer._id}`}
                multiple
                type="file"
                value={null}
                onChange={(event) => handleUploadPrivacy(event, customer._id)}
              />
              <LoadingButton
                loading={uploadPrivacyLoading}
                variant="contained"
                component="span"
                color={uploadPrivacyButtonBackground}
              >
                {uploadPrivacyButtonText}
              </LoadingButton>
            </label>
          </Stack>
        </Stack>

        {!customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
          ?.number && (
            <Typography variant="caption">
              Sending a privacy link requires a customer mobile number
            </Typography>
          )}
      </Grid>

      {customer && (
        <Identity customer={customer} applicationId={application._id} />
      )}



      {/* {customer && (
        <CreditProfile
          customer={customer}
          applicationId={application._id}
        />
      )} */}

      <Grid item xs={12} style={{ marginBottom: "30px" }}>
        <Typography style={{ marginBottom: "10px" }}>
          Bank statements
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        // customer={customer}
        >{customer?.documents?.length > 0 &&
          customer?.documents
            ?.filter((doc) => doc.name === "Bank statements")
            .map((doc) => (

              <Document
                document={doc}
                customer={customer}
                width={imageWidth}
              />

            ))}
        </Stack>

        {/* {customer?.bankStatements && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
              Bank accounts summary (current balance)
            </Typography>
            <Grid item container style={{ margin: "0 0 20px 0" }}>
              {customer?.bankStatements &&
                customer?.bankStatements?.bankAccounts?.map((bank, i) => {
                  return (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {bank.bankName}
                      </Typography>
                      {bank.bankAccounts.map((account) => {
                        return (
                          <Typography variant="body2">
                            <strong>{account.accountName}</strong>:{" "}
                            {account.currentBalance}
                          </Typography>
                        );
                      })}
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )} */}

        {/* {customer?.bankStatements && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
              Credits
            </Typography>
            <Grid item container style={{ margin: "0 0 20px 0" }}>
              {customer?.bankStatements &&
                customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                  if (i < 3) {
                    return (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {metric.name}
                        </Typography>
                        <Typography variant="body2">{metric.value}</Typography>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </>
        )} */}

        {/* {customer?.bankStatements && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
              Responsible lending flags
            </Typography>
            <Grid item container style={{ margin: "0 0 20px 0" }}>
              {customer?.bankStatements &&
                customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                  if (i > 2 && i < 7) {
                    return (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {metric.name}
                        </Typography>
                        <Typography variant="body2">{metric.value}</Typography>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </>
        )} */}

        {/* {customer?.bankStatements && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
              Liabilities
            </Typography>
            <Grid item container style={{ margin: "0 0 20px 0" }}>
              {customer?.bankStatements &&
                customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                  if (i > 6 && i < 10) {
                    return (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {metric.name}
                        </Typography>
                        <Typography variant="body2">{metric.value}</Typography>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </>
        )} */}

        {/* {customer?.bankStatements && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
              Debits
            </Typography>
            <Grid item container style={{ margin: "0 0 20px 0" }}>
              {customer?.bankStatements &&
                customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                  if (i > 9 && metric.value * 1 !== 0) {
                    return (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {metric.name}
                        </Typography>
                        <Typography variant="body2">{metric.value}</Typography>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </>
        )} */}

        {/* {customer?.documents?.length > 0 &&
          customer?.documents
            ?.filter((doc) => doc.name === "Bank statements")
            .map((doc) => (
              <Grid ref={ref} item xs={4}>
                <Document
                  document={doc}
                  customer={customer}
                  width={imageWidth}
                />
              </Grid>
            ))} */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}

        // customer={customer}
        >
          <LoadingButton
            color={bankStatementsButtonBackground}
            variant="outlined"
            loading={bankStatementsLoading}
            style={{ marginBottom: "5px" }}
            disabled={
              !customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
                ?.number
            }
            onClick={() => {
              console.log("phone:", customer);
              handleSendBankStatementsLink(
                customer._id,
                application._id,
                customer?.phoneNumbers.filter((a) => a.status === "current")[0]
                  .number
              );
              handleSaveCreditProfile({ bankstatementsLinkSent: true })
              dispatch(saveValue({ linkSent: true }));
              setDelay(30000);
            }}
          >
            {bankStatementsButtonText}
          </LoadingButton>

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          // customer={customer}
          >
            <label htmlFor={`upload-bankstatements-${customer._id}`}>
              <Input
                accept="*"
                id={`upload-bankstatements-${customer._id}`}
                multiple
                type="file"
                value={null}
                onChange={(event) => handleUploadBankstatements(event, customer._id)}
              />
              <LoadingButton
                loading={uploadBankstatementsLoading}
                variant="contained"
                component="span"
                color={bankStatementsUploadButtonBackground}
              >
                {bankStatementsUploadButtonText}
              </LoadingButton>
            </label>
          </Stack>

        </Stack>
        <Grid style={{ marginBottom: "15px" }}>
          {!customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
            ?.number && (
              <Typography variant="caption" >
                Requires customer mobile phone number
              </Typography>
            )}
        </Grid>

        <Grid item container spacing={2}>
          <Grid item md={4} sm={4}>
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              type="text"
              name="referral-code"
              label="Illion referral code"
              variant="outlined"
              value={illionReferralID}
              onChange={(event) => setIllionRefferalID(event.target.value)}
              onBlur={() => handleSaveCreditProfile({ illionReferralID: illionReferralID })}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              type="text"
              name="doc-id"
              label="Illion doc ID"
              variant="outlined"
              value={bankstatementsDocID}
              onChange={(event) => setBankstatementsDocID(event.target.value)}
              onBlur={() => handleSaveCreditProfile({ bankstatementsDocID: bankstatementsDocID })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginBottom: "15px" }}>
          Add additional documents
        </Typography>
        <Grid item xs={12} sm={12}>
          <Stack spacing={1} direction="row">
            <TextField
              id="outlined-basic"
              type="text"
              name="applicant_firstname"
              label="Document name"
              variant="outlined"
              size="small"
              value={documentName || ""}
              onChange={(event) => setDocumentName(event.target.value)}
            />

            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
            // customer={customer}
            >
              <label htmlFor={`upload-document-${customer._id}`}>
                <Input
                  accept="*"
                  id={`upload-document-${customer._id}`}
                  multiple
                  type="file"
                  value={null}
                  onChange={(event) =>
                    handleUploadDocument(event, documentName, customer._id)
                  }
                />
                <LoadingButton
                  loading={documentUploadLoading}
                  variant="contained"
                  component="span"
                  color={uploadDocumentButtonBackground}
                  disabled={!documentName}
                >
                  {uploadDocumentButtonText}
                </LoadingButton>
              </label>
            </Stack>
          </Stack>
          {!documentName && (
            <Typography variant="caption">
              Enter a document name to upload a document
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {customer?.documents?.length > 0 && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography id="input-slider" gutterBottom>
                Documents
              </Typography>
              <Grid container item xs={12} spacing={2}>
                {customer?.documents?.length > 0 ? (
                  customer?.documents?.map((doc) => {
                    if (doc.name !== "Privacy agreement" && doc.name !== "Bank statements") {
                      return (
                        <Grid ref={ref} item>
                          <Document
                            document={doc}
                            customer={customer}
                            width={imageWidth}
                          />
                        </Grid>
                      );
                    }

                    return;
                  })
                ) : (
                  <>
                    <Grid item xs={3}>
                      <input
                        id="contained-button-file"
                        type="file"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="contained-button-file">
                        <Skeleton
                          animation={false}
                          variant="rectangular"
                          // width={210}
                          height={100}
                        />
                      </label>
                      {/* <Skeleton />
              <Skeleton /> */}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
