import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationsSelector,
  getAllApplications,
  saveValue,
} from "../store/slices/applicationsSlice";
import _ from "lodash";
import moment from "moment"
import { useEffect } from "react";
import { userSelector } from "src/store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

 const dollarStringOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }

const brokerColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "applicationId",
    headerName: "ID",
    type: "string",
    width: 100,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 100,
    // editable: true,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 100,
    // editable: true,
  },

  {
    field: "status",
    headerName: "Status",
    width: 110,
    // editable: true,
  },
  {
    field: "entityName",
    headerName: "Entity",
    width: 250,
    // editable: true,
  },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    // editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    // editable: true,
  },
  {
    field: "asset",
    headerName: "Asset",
    type: "string",
    width: 250,
    // editable: true,
  },
  // {
  //   field: "assetValue",
  //   headerName: "Asset value",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  {
    field: "loanAmount",
    headerName: "Loan amount",
    type: "string",
    width: 110,
    editable: false,
  },


];

const masterColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "applicationId",
    headerName: "ID",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 100,
    // editable: true,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 100,
    // editable: true,
  },

  {
    field: "status",
    headerName: "Status",
    width: 110,
    // editable: true,
  },
  {
    field: "entityName",
    headerName: "Entity",
    width: 250,
    // editable: true,
  },
  {
    field: "brokerOrganisation",
    headerName: "Broker",
    width: 150,
    // editable: true,
  },
  {
    field: "brokerContact",
    headerName: "Broker contact",
    width: 150,
    // editable: true,
  },
  {
    field: "asset",
    headerName: "Asset",
    type: "string",
    width: 250,
    // editable: true,
  },
  // {
  //   field: "assetValue",
  //   headerName: "Asset value",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  {
    field: "loanAmount",
    headerName: "Loan amount",
    type: "string",
    width: 110,
    editable: false,
  },


];

export default function ApplicationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const response = useSelector(applicationsSelector);

  const applications = response.applications
  const { isLoading, page } = response

  console.log({ applications })

  const [rows, setRows] = useState([]);

  // const [page, setPage] = useState(0);

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    console.log({ newPage })
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllApplications(user, newPage + 1);
    dispatch(saveValue({ page: newPage }))
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(
    applications?.totalDocs || 0,
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      applications?.totalDocs !== undefined
        ? applications?.totalDocs
        : prevRowCountState,
    );
  }, [applications?.totalDocs, setRowCountState]);

  function handleGetAllApplications(userId, cursor) {
    dispatch(getAllApplications({ userId, cursor }));
  }

  useEffect(() => {
    console.log(user);
    console.log("mapPageToNextCursor", applications?.page);
    if (user) handleGetAllApplications(user, page + 1 || 1);
    // dispatch(saveValue({ page: newPage }))
  }, [user]);

  useEffect(() => {

    setRows(
      applications?.docs?.map((doc) => {
        // const newDoc = _.merge({}, doc);

        const date = new Date(doc.createdAt);

        return {
          id: doc?._id,
          applicationId: doc?.applicationId,
          status: doc?.status,
          entityName: doc?.entities?.[0]?.entityName,
          firstName: doc.customers?.[0]?.firstName || "No",
          lastName: doc.customers?.[0]?.lastName || "customer",
          asset: doc?.asset?.make || doc.asset?.model ? `${doc?.asset?.ageOfAsset || ""} ${doc?.asset?.make || ""} ${doc.asset?.model || ""}` : doc?.asset?.assetType,
          assetValue: doc?.asset?.assetValue,
          loanAmount: doc?.loanDetails?.loanAmount.toLocaleString("en-US", dollarStringOptions),
          // term: doc?.loanDetails?.term,
          // serviceFees: doc.transactions.reduce(
          //   (acc, item) => acc + item.fees,
          //   0
          // ),
          brokerOrganisation: doc?.user?.organisation?.name,
          brokerContact: doc?.user?.firstName + " " + doc?.user?.lastName,
          applicationId: doc?.applicationId,
          startDate: `${date.getDate()}.${date.getMonth() + 1
            }.${date.getFullYear()}`,
          time: moment(doc?.createdAt).format("h:mm a")
        };
      })
    );
    // console.log("rows", rows);
  }, [applications]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      style={{ marginTop: "64px", padding: "20px" }}
    >
      <Grid item style={{ paddingTop: "0" }}>
        <Typography
          variant="h1"
          style={{ fontSize: "28px", fontWeight: 800, letterSpacing: "-1.5px" }}
        >
          Applications
        </Typography>
      </Grid>
      <Grid item >
        <DataGrid
          autoHeight
          autoPageSize
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={(event) => {
            console.log(event);
            navigate(`/dashboard/application/${event.id}`);
          }}
          rows={rows || []}
          columns={user.userType === "master" ? masterColumns : brokerColumns}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff" }}
          // initialState={initialState}
          pagination
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
