import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, userSelector } from "../store/slices/userSlice";
import _ from "lodash";
import { Typography } from "@mui/material";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector(userSelector);

  async function login() {
    await dispatch(loginUser({ email, password })).unwrap();
  }

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  return (
    <>
      <Grid container style={{ padding: "30px 50px 100px" }}>
        <Typography>Page not found.</Typography>
      </Grid>
    </>
  );
}
