import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  userSelector,
  createSubscription,
  getUser,
} from "../store/slices/userSlice";

export default function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const user = useSelector(userSelector);
  const { isCreatingSubscription } = useSelector(userSelector);

  // console.log("user", user);

  function handleCreateSubscription() {
    console.log("clicking");
    dispatch(createSubscription());
  }

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (user.clientSecret) {
      navigate("/subscribe/payment-details")
    }
  }, [user]);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="center"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Typography
            variant="h5"
            fontWeight="800"
            style={{ letterSpacing: "-1.2px" }}
          >
            Subscribe
          </Typography>

          <br />

          <LoadingButton
            // fullWidth
            // endIcon={<SendOutlinedIcon />}
            loading={isCreatingSubscription}
            // loadingIndicator="Generating..."
            loadingPosition="end"
            variant="contained"
            color="secondary"
            style={{ margin: "20px 0 40px", justifySelf: "start" }}
            onClick={handleCreateSubscription}
          >
            Subscribe
          </LoadingButton>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          height="100vh"
          justifyContent="center"
          // alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
           <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left" }}>
            Financeable
          </Typography>
          <br />
          <br /> 
          <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}> 
            This is it.
          </Typography>
          <br />
          <br />
          <Typography variant="h3">
            The last finance platform you'll ever sign up for.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
