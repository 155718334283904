import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import { addEmail } from "../store/slices/applicationFormSlice";

export default function Email({ email, customerId }) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(email?.address);

  const handleAddEmail = () => {
    // console.log("update phoneNumber");
    dispatch(
      addEmail({
        emailId: email?._id,
        customerId,
        address,
      })
    );
  };

  const handleEmailValue = (event) => {
    setAddress(event.target.value);
  };

  useEffect(() => {
    // console.log("email", email);
  }, []);

  return (
    <>
      <TextField
        error={!address}
        helperText={!address ? "Required" : ""}
        id="outlined-basic"
        type="text"
        name="applicant_phoneNumber"
        label="Email"
        variant="outlined"
        value={address || ""}
        onChange={handleEmailValue}
        onBlur={() => handleAddEmail()}
        inputProps={{
          autoComplete: "none"
        }}
      />
    </>
  );
}
