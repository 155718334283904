import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createFinanceItem,
  deleteFinanceItem,
  updateLiabilities,
} from "../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import { Button, IconButton, Stack } from "@mui/material";

export default function Liabilities({ liabilities, index }) {
  const dispatch = useDispatch();
  const [liabilitiesValues, setLiabilitiesValues] = useState(
    liabilities?.items || []
  );
  const [newLiabilityName, setNewLiabilityName] = useState("");
  const [isHovering, setIsHovering] = useState({});

  const handleLiabilities = (update) => {
    // console.log("update phoneNumber");
    dispatch(updateLiabilities(update));
  };

  async function handleCreateLiability() {
    console.log("creating");
    await dispatch(
      createFinanceItem({
        data: {
          name: _.camelCase(newLiabilityName),
          label: newLiabilityName,
          liabilitiesId: liabilities?._id,
        },
        endpoint: "liabilities",
      })
    ).unwrap();
    setNewLiabilityName("");
  }

  async function handleDeleteLiability(data) {
    console.log("deleting");
    await dispatch(
      deleteFinanceItem({
        data,
        endpoint: "liabilities",
      })
    ).unwrap();
  }

  // useEffect(() => {
  //   setLiabilitiesValues(liabilities.items);
  // }, [liabilities]);

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      spacing={0}
      direction="column"
      style={{ alignContent: "flex-start" }}
    >
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
        Liabilities
      </Typography>

      {/* <Grid item sm={12}> */}
      <Stack spacing={1}>
        {liabilities?.items?.map((liability, i) => (
          <TextField
            id="outlined-basic"
            type="text"
            size="small"
            name={liability.name}
            label={liability.label}
            variant="outlined"
            error={i === 0 && index === 0 ? !liability.value : false}
            helperText={i === 0 && index === 0 && !liability.value ? "Required" : ""}
            value={
              liabilitiesValues.filter(
                (value) => value._id === liability._id
              )?.[0]?.value
            }
            onMouseEnter={() => {
              setIsHovering({ ...isHovering, [liability._id]: true });
            }}
            onMouseLeave={() => {
              setIsHovering({ ...isHovering, [liability._id]: false });
            }}
            onChange={(event) => {
              const currentLiability = liabilitiesValues.filter(
                (value) => value._id === liability._id
              );
              const newLiabilityValues = liabilitiesValues.filter(
                (value) => value._id !== liability._id
              );
              currentLiability.value = event.target.value;
              setLiabilitiesValues([
                ...newLiabilityValues,
                ...currentLiability,
              ]);
            }}
            onBlur={(event) =>
              handleLiabilities({
                liabilityId: liability._id,
                value: event.target.value,
                liabilitiesId: liabilities._id,
              })
            }
            inputProps={{
              autoComplete: "none",
            }}
            InputProps={{
              startAdornment: liabilitiesValues[liability.name] ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
              endAdornment: isHovering[liability._id] && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      handleDeleteLiability({
                        liabilitiesId: liabilities._id,
                        liabilityId: liability._id,
                      });

                      // setLiabilitiesValues(
                      //   liabilitiesValues.filter(
                      //     (value) => value._id !== liability._id
                      //   )
                      // );

                      // console.log(liabilitiesValues, liability._id);
                    }}
                    edge="end"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}

        <TextField
          id="outlined-basic"
          type="text"
          size="small"
          // name={liability.name}
          label="Add liability"
          variant="outlined"
          onChange={(event) => setNewLiabilityName(event.target.value)}
          value={newLiabilityName}
          placeholder="Liability name"
          // onChange={(event) =>
          //   setLiabilitiesValues(
          //     _.merge(liabilitiesValues, {
          //       [liabilities[liability].name]: liabilities[liability].value,
          //     })
          //   )
          // }
          // onBlur={() =>
          //   handleLiabilities({ [liability.name]: liabilitiesValues[liability.name] })
          // }
          InputProps={{
            // endAdornment: liabilitiesValues[liability.name] ? (
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newLiabilityName) {
                      handleCreateLiability(event);
                    }
                  }}
                  style={{
                    background: newLiabilityName ? "#9c27b0" : "none",
                    color: newLiabilityName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
            // ) : null,
          }}
        />
      </Stack>
    </Grid>
  );
}
