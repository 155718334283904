import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import {
  addPhoneNumber,
  saveCustomer,
} from "../store/slices/applicationFormSlice";

export default function PhoneNumber({ phoneNumber, customerId }) {
  const dispatch = useDispatch();
  const [number, setNumber] = useState();

  const handleAddPhoneNumber = () => {
    // console.log("update phoneNumber");
    dispatch(
      addPhoneNumber({
        phoneNumberId: phoneNumber?._id,
        customerId,
        number,
      })
    );
  };

  const handlePhoneNumberValue = (event) => {
    setNumber(event.target.value);
  };

  useEffect(() => {
    // console.log("phoneNumber", phoneNumber);
    setNumber(phoneNumber?.number);
  }, []);

  return (
    <>
      <TextField
        error={!number}
        helperText={!number ? "Required" : ""}
        id="outlined-basic"
        type="text"
        name="applicant_phoneNumber"
        label="Mobile number"
        variant="outlined"
        value={number || ""}
        onChange={handlePhoneNumberValue}
        onBlur={() => handleAddPhoneNumber()}
        inputProps={{
          autoComplete: "none"
        }}
      />
    </>
  );
}
