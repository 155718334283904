import React, { useEffect, useState } from "react";
// import io from "socket.io-client";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./store";
// routes
import Router from "./routes";
// theme
// components

import { purple, cyan, blueGrey } from "@mui/material/colors";

// import colours from "./style/color";

// ----------------------------------------------------------------------
let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#125984",
    },
    secondary: {
      main: "#125984",
    },
    background: {
      default: blueGrey[50],
      paper: "white",
    },
  },

  typography: {
    body1: {
      letterSpacing: "0.5px",
    },
    fontFamily: [
      "Cabin",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  // buttons: {
  //   fontFamily: [
  //     "Cabin",
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(","),
  // },
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       borderWidth: "2px",
    //     },
    //   },
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: "lowercase",
    //       fontWeight: 500,
    //       fontSize: "1rem",
    //       letterSpacing: "0.1px"
    //     },
    //   },
    // },
  },
});

theme = responsiveFontSizes(theme);

export default function App() {
  // const [socket, setSocket] = useState(null);

  // useEffect(() => {
  // const newSocket = io(process.env.REACT_APP_API_URL, {
  //   transports: ["websocket"],
  // });
  // setSocket(newSocket);
  // return () => newSocket.close();
  // }, [setSocket]);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Router
          //  socket={socket}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}
