import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Autocomplete from '@mui/material/Autocomplete';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";
import { isDigitsOnly } from "src/utils/isDigitsOnly";

import { userSelector } from "../../store/slices/userSlice";
import {
  createOrganisation,
  getOrganisation,
  organisationSelector,
  saveValue,
  requestToJoin,
} from "../../store/slices/organisationSlice";
import { Box, Button, Fade, Grow, Slide, Typography } from "@mui/material";

export default function Organisation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [businessSearchValue, setBusinessSearchValue] = useState("");
  const [businessSearchInputText, setBusinessSearchInputText] = useState("");
  const [newBusinessValue, setNewBusinessValue] = useState("");
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [ABREntityDetails, setABREntityDetails] = useState(null);
  // const [isCreating, setIsCreating] = useState(false);

  const organisation = useSelector(organisationSelector);
  const user = useSelector(userSelector);
  const { isCreating, isNew, requestSuccess } = organisation;

  console.log({ organisation });

  async function handleCreateOrganisation() {
    // dispatch(saveValue({ isCreating: true }));
    await dispatch(
      createOrganisation({
        name: ABREntityDetails.EntityName,
        status: "active",
        abn: ABREntityDetails.Abn,
        acn: ABREntityDetails.Acn,
        gst: ABREntityDetails.Gst,
        entityType: ABREntityDetails.EntityTypeName,
        businessNames: ABREntityDetails.BusinessName,
        postCode: ABREntityDetails.AddressPostcode,
      })
    ).unwrap();
  }

  function handleGetOrganisation(abn) {
    // dispatch(saveValue({ isCreating: true }));
    dispatch(getOrganisation(abn));
  }

  const handleEntityDetails = async (details) => {
    // dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    console.log("ABREntityDetails", abrData);
    setABREntityDetails(abrData);
    handleGetOrganisation(details.Abn);
  };

  const debounced = debounce(async (inputValue) => {
    console.log("inputValue", inputValue, isDigitsOnly.test(inputValue));
    if (inputValue) {
      setLoadingBusinessSearch(true);
      if (isDigitsOnly.test(inputValue)) {
        const businessByABN = await getBusinessByABN(inputValue);
        console.log(businessByABN);
        setLoadingBusinessSearch(false);
        if (businessByABN.Abn.length > 0) {
          return setEntityOptions([businessByABN]);
        }
      }

      const businesses = await getBusinessByName(inputValue);
      if (businesses?.Names?.length > 0) {
        setLoadingBusinessSearch(false);
        return setEntityOptions(
          businesses.Names.map((i) => {
            // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
            return i;
          })
        );
      }

      setEntityOptions([
        {
          Name: "No results found",
        },
      ]);
    }
  }, 500);

  function handleRequestToJoin() {
    // dispatch(saveValue({ isCreating: true }));
    dispatch(requestToJoin({ organisation }));
  }

  useEffect(() => {
    console.log("organisation?._id && isNew", user, isNew);
    if (organisation?._id && isNew) {
      navigate("/setup/invite-users");
    }
    // if (organisation?._id && !isNew)
  }, [organisation]);

  useEffect(() => {
    console.log("user", user);
    if (organisation?._id) navigate("/dashboard/applications");
  }, [user]);

  useEffect(() => {
    debounced(businessSearchInputText);

    return () => debounced.cancel();
  }, [businessSearchValue, businessSearchInputText]);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          textAlign="center"
          style={{ padding: "10vh 100px", marginTop: "10vh" }}
        >
          {!requestSuccess && (
            <>
              <Typography
                variant="h4"
                fontWeight="800"
                style={{ letterSpacing: "-1.8px" }}
              >
                Add Your Business/Organisation
              </Typography>
              {/* <br />
          <br /> */}
              {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
              <br />
              {/* <br /> */}
              <Typography
                variant="h6"
                fontWeight="400"
                style={{ margin: "0 0 20px 0", fontSize: "16px" }}
              >
                Search below using an ABN or business name.
              </Typography>

              <Autocomplete
                fullWidth
                autoComplete
                autoSelect={true}
                name="Business Search"
                // {...props}
                // value={businessSearchValue}
                noOptionsText="Type to start search"
                loadingText="Searching..."
                defaultValue=""
                clearOnBlur={false}
                blurOnSelect={true}
                onChange={(event, newValue, reason) => {
                  console.log(newValue, reason);
                  if (reason === "select-option") {
                    if (newValue) {
                      setNewBusinessValue(newValue);
                      setBusinessSearchValue(
                        newValue?.Name || newValue?.entityName
                      );

                      return;
                    }

                    setBusinessSearchValue("");
                  }

                  if (reason === "blur") handleEntityDetails(newValue);

                  // setBusinessSearchValue("");
                }}
                filterOptions={(x) => x}
                onInputChange={(event, newInputValue) => {
                  console.log(newInputValue);
                  if (newInputValue !== "undefined undefined")
                    setBusinessSearchInputText(newInputValue);
                  else setBusinessSearchInputText("");
                  if (newInputValue === "") setEntityOptions([]);
                }}
                getOptionLabel={(option) => {
                  return `${option.Name || option.EntityName} ${option.Abn}`;
                }}
                inputValue={businessSearchInputText}
                options={entityOptions}
                loading={loadingBusinessSearch}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    // label="Asynchronous"
                    label="Search business/organisation"
                    // value={entity?.entityName}
                    placeholder="Search with ABN or business name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingBusinessSearch ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    style={{ margin: "0 0 20px 0", background: "#ffffff" }}
                  />
                )}
              />
              {organisation._id && (
                <>
                  <Grow
                    in={organisation._id}
                    style={{ transformOrigin: "top center" }}
                    {...(organisation._id ? { timeout: 500 } : {})}
                  >
                    {/* <Box> */}
                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>
                        {organisation.name} is already on Financeable! 🙌
                      </strong>
                    </Typography>
                  </Grow>
                  <Grow
                    in={organisation._id}
                    style={{
                      transformOrigin: "top center",
                      transitionDelay: "100ms",
                    }}
                    {...(organisation._id ? { timeout: 500 } : {})}
                  >
                    <Typography style={{ marginBottom: "20px" }}>
                      Request to join {organisation.name} below or search for
                      another organisation.
                    </Typography>
                  </Grow>
                  <Grow
                    in={organisation._id}
                    style={{
                      transformOrigin: "top center",
                      transitionDelay: "200ms",
                    }}
                    {...(organisation._id ? { timeout: 500 } : {})}
                  >
                    <LoadingButton
                      fullWidth
                      color="secondary"
                      // endIcon={<SendOutlinedIcon />}
                      loading={false}
                      // loadingIndicator="Generating..."
                      loadingPosition="end"
                      variant="contained"
                      // disabled={!ABREntityDetails}
                      onClick={handleRequestToJoin}
                    >
                      Request to join
                    </LoadingButton>
                    {/* </Box> */}
                  </Grow>
                </>
              )}

              {isNew && (
                <LoadingButton
                  fullWidth
                  color="secondary"
                  // endIcon={<SendOutlinedIcon />}
                  loading={isCreating}
                  // loadingIndicator="Generating..."
                  loadingPosition="end"
                  variant="contained"
                  // disabled={!ABREntityDetails}
                  onClick={() => handleCreateOrganisation()}
                >
                  Add my organisation
                </LoadingButton>
              )}
            </>
          )}

          {requestSuccess && (
            <>
              <Typography
                variant="h4"
                fontWeight="800"
                style={{ letterSpacing: "-1.8px" }}
              >
                Your request has been sent!
              </Typography>
              <br></br>
              <Typography style={{ textAlign: "justify" }}>
                Your request is now awaiting approval from the GGF. Once it's
                accepted your request you'll join the {organisation.name}{" "}
                account.
              </Typography>
              <br></br>
              {/* <Typography style={{ textAlign: "justify" }}>
                In the meantime, feel free the start using the plarform.
              </Typography>
              <br></br>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => navigate("/dashboard/applications")}
              >
                Go to dashboard
              </Button> */}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
