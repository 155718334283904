import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createFinanceItem,
  deleteFinanceItem,
  updateAssets,
} from "../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import { Button, IconButton, Stack } from "@mui/material";

export default function Assets({ assets, index }) {
  const dispatch = useDispatch();
  const [assetsValues, setAssetsValues] = useState(assets?.items || []);
  const [newAssetName, setNewAssetName] = useState("");
  const [isHovering, setIsHovering] = useState({});

  const handleAssets = (update) => {
    console.log("update", update);
    dispatch(updateAssets(update));
  };

  async function handleCreateAsset() {
    console.log("creating");
    await dispatch(
      createFinanceItem({
        data: {
          name: _.camelCase(newAssetName),
          label: newAssetName,
          assetsId: assets?._id,
        },
        endpoint: "customer-assets",
      })
    ).unwrap();
    setNewAssetName("");
  }

  async function handleDeleteAsset(data) {
    console.log("deleting");
    await dispatch(
      deleteFinanceItem({
        data,
        endpoint: "customer-assets",
      })
    ).unwrap();
  }

  // useEffect(() => {
  //   setAssetsValues(assets.items);
  // }, [assets]);

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      spacing={0}
      direction="column"
      style={{ alignContent: "flex-start" }}
    >
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
        Assets
      </Typography>

      {/* <Grid item sm={12}> */}
      <Stack spacing={1}>
        {assets?.items?.map((asset, i) => (
          <TextField
            id="outlined-basic"
            type="text"
            size="small"
            name={asset.name}
            label={asset.label}
            variant="outlined"
            error={i === 0 && index === 0 ? !asset.value : false}
            helperText={i === 0 && index === 0 && !asset.value ? "Required" : ""}
            value={
              assetsValues.filter((value) => value?._id === asset?._id)?.[0]
                ?.value
            }
            onMouseEnter={() => {
              setIsHovering({ ...isHovering, [asset._id]: true });
            }}
            onMouseLeave={() => {
              setIsHovering({ ...isHovering, [asset._id]: false });
            }}
            onChange={(event) => {
              const currentAsset = assetsValues.filter(
                (value) => value._id === asset._id
              );
              const newAssetValues = assetsValues.filter(
                (value) => value._id !== asset._id
              );
              currentAsset.value = event.target.value;
              setAssetsValues([...newAssetValues, ...currentAsset]);
            }}
            onBlur={(event) =>
              handleAssets({
                assetId: asset._id,
                value: event.target.value,
                assetsId: assets._id,
              })
            }
            inputProps={{
              autoComplete: "none",
            }}
            InputProps={{
              startAdornment: assetsValues[asset.name] ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
              endAdornment: isHovering[asset._id] && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleDeleteAsset({
                        assetId: asset._id,
                        assetsId: assets._id,
                      })
                    }
                    edge="end"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}

        <TextField
          id="outlined-basic"
          type="text"
          size="small"
          // name={asset.name}
          label="Add asset"
          variant="outlined"
          onChange={(event) => setNewAssetName(event.target.value)}
          value={newAssetName}
          placeholder="Asset name"
          // value={assetsValues[asset.name] || ""}
          // onChange={(event) =>
          //   setAssetsValues(
          //     _.merge(assetsValues, {
          //       [assets[asset].name]: assets[asset].value,
          //     })
          //   )
          // }
          // onBlur={() =>
          //   handleAssets({ [asset.name]: assetsValues[asset.name] })
          // }
         
          InputProps={{
            
            // endAdornment: assetsValues[asset.name] ? (
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newAssetName) {
                      handleCreateAsset(event);
                    }
                  }}
                  style={{
                    background: newAssetName ? "#9c27b0" : "none",
                    color: newAssetName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
            
            // ) : null,
          }}
        />
      </Stack>
    </Grid>
  );
}
