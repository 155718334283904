import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Grid from "@mui/material/Grid";
// import LoadingButton from "@mui/lab/LoadingButton";
// import TextField from "@mui/material/TextField";
import _ from "lodash";

// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, userSelector } from "../../store/slices/userSlice";

import User from "./User";
// import ConfirmEmail from "./ConfirmEmail";
// import Organisation from "./Organisation";

export default function Register() {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const user = useSelector(userSelector);

  // useEffect(() => {
  //   if (!user) navigate("/login");
  // }, [user]);

  return (
    <>
      <User />
      {/* <ConfirmEmail />
      <Organisation /> */}
    </>
  );
}
