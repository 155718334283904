import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  Editor,
  // MUIEditorState,
  // toolbarControlTypes,
} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  saveValue,
  userDetailsSelector,
  updateNotes,
  applicationSelector,
} from "src/store/slices/applicationFormSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Link, Stack, Tooltip } from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
  LANG_PREFIX,
} from "draft-js";
import _, { debounce, merge, startCase, union } from "lodash";

import ApplicationProgress from "./ApplicationProgress"

const drawerWidth = 300;
// const drawerWidth = 300;

let rawContent = {
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: "2vm6d",
      text: "Please state the nature of this clients business, directors background and current work/income sources: ",
      type: "default",
      color: "#bbbbbb",
    },
  ],
  entityMap: {},
};

// const config = {
//   toolbar: {
//     className: "MuiPaper-elevation",
//     wrapperElement: "div",
//     // psotion: "bottom",
//     controls: [
//       toolbarControlTypes.bold,
//       toolbarControlTypes.italic,
//       toolbarControlTypes.underline,
//       toolbarControlTypes.undo,
//       toolbarControlTypes.redo,
//       toolbarControlTypes.blockType,
//       // toolbarControlTypes.fontSize,
//       toolbarControlTypes.textAlign,
//       toolbarControlTypes.unorderedList,
//       toolbarControlTypes.orderedList,
//     ],
//     controlsConfig: {
//       blockType: {
//         options: [
//           {
//             value: "default",
//             text: "normal",
//           },
//           // {
//           //   value: "header-one",
//           //   text: "header1",
//           // },
//           {
//             value: "header-two",
//             text: "header 1",
//           },
//           {
//             value: "header-three",
//             text: "header 2",
//           },
//           // {
//           //   value: 'blockquote',
//           //   text: 'Quote',
//           // },
//         ],
//       },
//     },

//     style: {
//       boxShadow: 0,
//       borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
//       borderRight: "1px solid rgba(0, 0, 0, 0.15)",
//       borderRadius: 0,
//       position: "fixed",
//       bottom: "0",
//       width: "300px",
//       background: "#fff",
//       zIndex: 1000000,
//     },
//   },
//   editor: {
//     wrapperElement: "div",
//     style: {
//       height: "calc(100vh - 64px - 144px - 100px)",
//       padding: "20px",
//       marginTop: "64px",
//     },
//   },
// };

const assetDetailsItems = [
  "ageOfAsset",
  "assetType",
  "assetValue",
  "typeOfSale",
  "make",
  "model"
]

let customerDetailsItems = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "emails",
  "phoneNumbers",
  "addresses",
  "livingArrangements",
  "IDNumber"
]

const primaryCustomerDetailsItems = [
  "customerAssets",
  "liabilities",
  "privacy",
  "bankStatements"
]

const primaryCustomerDetailsItemsCount = primaryCustomerDetailsItems.length + customerDetailsItems.length

export default function Notes({ handleApplicationComplete }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { open } = useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);

  const notes = application?.notes?.value;

  // console.log("NOTES", notes);

  if (notes) rawContent = notes;

  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(convertFromRaw(notes || rawContent))
  );

  const [rawText, setRawText] = React.useState(0);
  const [applicationProgress, setApplicationProgress] = React.useState(0);
  const [assetDetailsCompleteList, setAssetDetailsCompleteList] = React.useState();

  function handleAssetItemsProgress() {
    let progress = 0
    let complete = []
    for (let [key, value] of Object.entries(application?.asset)) {
      // console.log(key, value)
      if (assetDetailsItems.includes(key) && value && value !== "") {
        progress++
        complete.push(key)
      }
    }
    // setAssetDetailsCompleteList(complete)

    complete = assetDetailsItems?.reduce((acc, val) => {
      // console.log(acc)
      if (complete?.includes(val)) {
        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }
      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])

    // console.log({ progress, complete })

    return { progress, complete }
  }

  function handleCustomerItemsProgress(i) {
    let progress = 0
    let complete = []
    let items = []

    if (i === 0) items = union(customerDetailsItems, primaryCustomerDetailsItems)
    else items = customerDetailsItems

    // console.log({ items })

    for (let [key, value] of Object.entries(application?.customers?.[i] || {})) {
      // console.log({ progress })
      // console.log({ key, value })
      if ((items.includes(key) && value && value !== "") || key === "documents" || key === "customerProfile") {
        // console.log({ key })
        if (key === "emails") {
          // console.log({ key })

          if (application?.customers?.[i]?.emails.length > 0) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "phoneNumbers") {
          if (application?.customers?.[i]?.phoneNumbers.length > 0) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "addresses") {
          if (application?.customers?.[i]?.addresses.length > 0) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        // console.log(key === "documents")
        if (key === "documents") {
          const hasPrivacy = application?.customers?.[i]?.documents?.length > 0 &&
            application?.customers?.[i]?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")[0]

          // console.log({ i })

          if (i === 0) {
            // console.log("primary")
            if (hasPrivacy) {
              progress++
              complete.push("privacy")

            }

            // console.log("bankStatements")
            const hasBankStatements = (application?.customers?.[i]?.documents?.length > 0 &&
              application?.customers?.[i]?.documents
                ?.filter((doc) => doc.name === "Bank statements")[0]) ||
              application?.customers?.[i]?.creditProfile?.bankstatementsLinkSent ||
              application?.customers?.[i]?.creditProfile?.bankstatementsDocID

            // console.log({ hasBankStatements })

            if (hasBankStatements) {
              progress++
              complete.push("bankStatements")

            }
          }

          continue
        }

        if (key === "customerProfile") {
          // console.log("customerProfile")

          if (application?.customers?.[i]?.customerProfile?.livingArrangements) {
            progress++
            complete.push("livingArrangements")
            continue
          }
          continue
        }

        if (value.items) {
          const hasValue = value.items.reduce((acc, val) => {
            return val.value ? true : acc
          }, false)

          // console.log({ hasValue })

          if (hasValue) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }

        if (items.includes(key) && value && value !== "") {
          progress++
          complete.push(key)
        }

        continue
      }
    }
    // console.log({ complete })
    const completeItems = items?.reduce((acc, val) => {
      // console.log(val)
      if (complete?.includes(val)) {

        if (val === "addresses") val = "address"
        if (val === "emails") val = "email"
        if (val === "phoneNumbers") val = "phoneNumber"

        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }

      if (val === "addresses") val = "address"
      if (val === "emails") val = "email"
      if (val === "phoneNumbers") val = "phoneNumber"

      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])

    // console.log({ progress, complete })

    return { progress, complete: completeItems }
  }

  const onChange = (newState) => {
    // console.log(newState);
    if (JSON.stringify(editorState) !== JSON.stringify(newState)) {
      setRawText(newState);
      setEditorState(newState);
    }
  };

  const debounced = debounce(async () => {
    const blocks = convertToRaw(editorState.getCurrentContent());
    const contentState = editorState.getCurrentContent();
    const entityMap = contentState.getEntityMap();
    // console.log(blocks);
    dispatch(
      updateNotes({
        value: blocks,
        application: application._id,
        _id: notes?._id,
      })
    );
  }, 5000);

  React.useEffect(() => {
    if (rawText !== 0) debounced(editorState);

    return () => debounced.cancel();
  }, [rawText]);

  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(convertFromRaw(notes || rawContent))
    );
  }, [notes]);

  React.useEffect(() => {
    const assetProgress = handleAssetItemsProgress()?.progress
    const entityProgress = application?.entities?.[0] ? 1 : 0
    const customersProgress = application?.customers?.map((val, i) => handleCustomerItemsProgress(i)?.progress)
    const customersProgressTotal = customersProgress?.reduce((acc, val) => acc + val, 0)
    const totalRequiredItems = 11 + application?.customers?.length * 8
    console.log({ assetProgress, entityProgress, customersProgress, customersProgressTotal })
    const totalProgress = assetProgress + entityProgress + customersProgressTotal
    setApplicationProgress(isNaN(parseInt(totalProgress / totalRequiredItems * 100)) ? 0 : parseInt(totalProgress / totalRequiredItems * 100))
    
    console.log({ totalProgress, totalRequiredItems})
    handleApplicationComplete(totalProgress / totalRequiredItems >= 1)
  }, [application]);

  return (
    <>
      {/* <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 1,
          "& .MuiDrawer-paper": {
            width: open ? drawerWidth : 0,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      > */}
      {/* <DrawerHeader></DrawerHeader> */}
      {/* <Divider /> */}
      <Card
        alignItems="flex-start"
        style={{
          margin: "0 0 10px 0",
          padding: "15px",
          width: "calc(25vw - 40px)",
          height: "calc(100vh - 140px)",
          position: "fixed",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarColor: "white rebeccapurple",
          scrollbarWidth: "thin",
          // background: setBackground()
        }}
      // style={{ background: productDetails.score ? "#dcedc8" : "#fce4ec" }}
      >
        <Stack alignContent="column">
          <Typography variant="subtitle" style={{ marginBottom: "20px" }}><strong>Application ID:</strong> {application.applicationId}</Typography>

        </Stack>
        <Stack alignContent="column" style={{
          // fontWeight: 600,
          marginBottom: "20px"
        }}>
          <Typography
            variant="subtitle"
            style={{
              fontWeight: 700,
              // marginBottom: "20px"
            }}>
            Application progress:
          </Typography>
          <Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <ApplicationProgress value={applicationProgress} />
              {/* <Typography
                variant="subtitle"
                style={{
                  // fontWeight: 600,
                  // marginBottom: "20px"
                }}>
                10%
              </Typography> */}
              <Typography
                variant="subtitle"
                style={{
                  // fontWeight: 600,
                  marginLeft: "10px"
                }}>
                {applicationProgress}%
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="baseline">
              <Typography>Items remaining:</Typography>
              <Tooltip arrow title={handleAssetItemsProgress()?.complete} placement="right">
                <Link
                  // href="#assetDetails"
                  underline="hover"
                  style={{ cursor: "pointer", textDecoration: handleAssetItemsProgress()?.progress === 6 && "line-through" }}
                  onClick={() => {
                    if (document.getElementById("assetDetails")) document.getElementById("assetDetails").scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }}
                >
                  Asset details: {handleAssetItemsProgress()?.progress}/6
                </Link>
              </Tooltip>


              <Link
                // href="#addEntity"
                underline="hover"
                style={{ cursor: "pointer", textDecoration: application?.entities?.[0] && "line-through" }}
                onClick={() => {
                  if (document.getElementById("addEntity")) document.getElementById("addEntity").scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}
              >{application?.entities?.[0] ? "Entity" : "Add entity"}</Link>

              {application?.customers?.[0] ?
                application?.customers?.map((customer, i) => <Tooltip arrow title={handleCustomerItemsProgress(i)?.complete} placement="right">
                  <Link
                    // href="#"
                    underline="hover"
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        handleCustomerItemsProgress(i).progress / (i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length) === 1 && "line-through"
                    }}
                    onClick={() => {
                      if (document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`)) document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}
                  >
                    Guarantor {i + 1} details: {handleCustomerItemsProgress(i).progress}/{i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length}
                  </Link>
                </Tooltip>) :
                <Link
                  // href="#addGuarantor"
                  underline="hover"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (document.getElementById("addGuarantor")) document.getElementById("addGuarantor").scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }}
                >
                  Add a guarantor
                </Link>
              }
            </Stack>

          </Stack>
        </Stack>
        {/* <div style={{ marginTop: "64px" }}> */}
        <Typography variant="subtitle" style={{ fontWeight: 700, }}>Comments/Background:</Typography>
        <Editor
          wrapperStyle={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            marginTop: "10px",
            padding: "0 10px"
          }}
          // toolbar={{
          //   options: [
          //     // "inline",
          //     "blockType",
          //     "fontSize",
          //     // "fontFamily",
          //     "list",
          //     // "textAlign",
          //     // "colorPicker",
          //     // "link",
          //     // "embedded",
          //     // "emoji",
          //     // "image",
          //     // "remove",
          //     // "history",
          //   ],
          //   list: {
          //     indent: false,
          //     outdent: false,
          //   },

          //   position: "bottom",

          // }}
          toolbarHidden
          editorState={editorState}
          onEditorStateChange={onChange}
        // onChange={onChange}
        // config={config}
        />
        {/* </div> */}
      </Card>
      {/* </Drawer> */}
    </>
  );
}
