import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import PaymentButton from "./PaymentButton";

import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  userSelector,
  validateEmail,
  updateUser,
  updatePhoneNumber,
  changePassword,
  getUser,
} from "../store/slices/userSlice";

import regex from "../utils/regex";
import { current } from "@reduxjs/toolkit";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const user = useSelector(userSelector);
  const { changePasswordSuccess, isChangingPassword, changePasswordFail } =
    useSelector(userSelector);

  // console.log("user", user);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: user.clientSecret,
  };

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="center"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Paper style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              fontWeight="800"
              style={{ letterSpacing: "-1.2px" }}
            >
              User profile
            </Typography>

            <br />
            <Elements stripe={stripePromise} options={options}>
              <PaymentElement />
              <PaymentButton />
            </Elements>
          </Paper>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          height="100vh"
          justifyContent="center"
          // alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
           <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left" }}>
            Financeable
          </Typography>
          <br />
          <br /> 
          <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}> 
            This is it.
          </Typography>
          <br />
          <br />
          <Typography variant="h3">
            The last finance platform you'll ever sign up for.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
