import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import MuiContainer from "@mui/material/Container";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import { validateToken, userSelector } from "../store/slices/userSlice";
import TopMenu from "../layouts/TopMenu";
// import Notes from "src/components/Notes";
import { Box, styled } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { SettingsInputComponent } from "@mui/icons-material";

import {
  saveValue,
  userDetailsSelector,
} from "../store/slices/applicationFormSlice";

const drawerWidth = 300;

const { innerWidth: width, innerHeight: height } = window;

const Container = styled(MuiContainer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${(width - 1200) / 2 - 300}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -30,
  }),
}));

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const pathRegex = new RegExp("dashboard/application/");

export default function Main(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [buttonText, setButtonText] = useState("Open");
  const { open } = useSelector(userDetailsSelector);

  // console.log(location.pathname);

  const handleDrawer = () => {
    dispatch(saveValue({ open: !open }));
  };

  useEffect(() => {
    open ? setButtonText("Close") : setButtonText("Open");
  });

  return (
    <>
      {/* <Button
        size="small"
        open={open}
        variant="contained"
        onClick={handleDrawer}
        color="secondary"
        style={{
          position: "fixed",
          left: "-1px",
          // top: "calc(50vh - 76px)",
          top: "115px",
          textOrientation: "upright",
          padding: "5px 16px 10px",
          minWidth: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          lineHeight: 0,
          // background: "#fff",
          display: location.pathname.match(pathRegex) ? "block" : "none",
          // marginLeft: "-1px"
          zIndex: 10000000
        }}
      >
        <span
          style={{
            textOrientation: "upright",
            writingMode: "vertical-rl",
            letterSpacing: "-2px",
          }}
        >
          {buttonText} Notes
        </span>
      </Button> */}
      <TopMenu open={open} />
      <Box sx={{ display: "flex", margin: "auto" }}>
        {/* <Notes open={open} /> */}

        <MuiContainer
          open={open}
          maxWidth={false}
          style={{ height: "100%", padding: "0" }}
        >
          {/* <Grid container style={{ height: "100%" }}> */}
          <Grid item xs={12}>
            {/* <Application {...props} /> */}
            <Outlet />
          </Grid>
          {/* </Grid> */}
        </MuiContainer>
      </Box>
    </>
  );
}
